import { Route } from 'react-router-dom';

const RenderRoute = (route) => {
	return (
		<Route exact path={route.path}>
			<route.component title={route.title} />
		</Route>
	);
};

export default RenderRoute;
