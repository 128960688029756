import { IonButton, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonImg, IonPage, IonRefresher, IonRefresherContent, IonSearchbar, IonSlide, IonSlides, IonTitle, IonToast, IonToolbar, isPlatform } from '@ionic/react';
import { cartOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../helpers/axiosInstance';
import middlewareAuth from '../../../middleware/middleware-auth';
import TAB_PATH from '../../../helpers/tabPath';
import { shieldCheckmarkOutline, giftOutline } from 'ionicons/icons';
import handleError from '../../../helpers/handleError';
import getQueryString from '../../../helpers/getQueryString';
import pluckUnique from '../../../helpers/pluckUnique';
import { AppIcon } from '@capacitor-community/app-icon';
import ChangeAppIcon from '../../../plugins/ChangeAppIcon';

const Discover = ({title}) => {

	middlewareAuth();

	let mainCategory = getQueryString(window, 'main_category');

	const slideOpts = {
		slidesPerView: 'auto',
		zoom: false,
		grabCursor: true,
	};

	const [companies, setCompanies] = useState();
	const [banners, setBanners] = useState();
	const [filteredCompanies, setFilteredCompanies] = useState();
	const [filters, setFilters] = useState([]);
	const [search, setSearch] = useState('');
	const [categoryFilter, setCategoryFilter] = useState('');
	const [showToast, setShowToast] = useState(false);
	const [toast, setToast] = useState({
		color: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
		message: process.env.REACT_APP_DEFAULT_TOAST_MESSAGE,
		duration: process.env.REACT_APP_DEFAULT_TOAST_DURATION
	});

	let getCompanies = () => {
		axiosInstance()
			.get('/companies')
			.then((res) => {
				setCompanies(res.data.data)
				setFilteredCompanies(res.data.data)
				setFilters(pluckUnique(res.data.data, 'main_category').sort((a, b) => a.localeCompare(b)))

				const preferredCompanySlug = res.data.data[0].slug;

				if ( isPlatform('ios') && !isPlatform('mobileweb') && !isPlatform('desktop') && !localStorage.appIconResetted ) {
					setIconIOS(preferredCompanySlug);
				}

				if ( isPlatform('android') && !isPlatform('mobileweb') && !isPlatform('desktop') && !localStorage.appIconResetted ) {
					setIconAndroid(preferredCompanySlug);
				}
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	let getBanners = () => {
		axiosInstance()
			.get('/banners')
			.then((res) => {
				setBanners(res.data.data)
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	let doRefresh = (event) => {
		getCompanies();
		getBanners();
		setSearch('');
		setCategoryFilter('');

		setTimeout(() => {
			event.detail.complete();
		}, 2000);
	}

	const setIconIOS = async (preferredCompanySlug) => {
		const isSupported = await AppIcon.isSupported();
		const currentIcon = await AppIcon.getName();

		if (isSupported.value && currentIcon.value === null) {
			await AppIcon.change({name: preferredCompanySlug, suppressNotification: true});
		}
	}

	const setIconAndroid = async (preferredCompanySlug) => {
		const { value } = await ChangeAppIcon.setIcon({ value: preferredCompanySlug });
	}

	useEffect(() => {
		getCompanies();
		getBanners();
	}, []);

	useEffect(() => {
		if ( companies ) {
			let temp = companies.filter(el => {
				if ( categoryFilter && search ) {
					return el.main_category && el.main_category.includes(categoryFilter) && (el.name.toLowerCase().includes(search.toLowerCase()) || (el.main_category && el.main_category.toLowerCase().includes(search.toLowerCase()))  || (el.category_alt && el.category_alt.toLowerCase().includes(search.toLowerCase())) || (el.category_alt_2 && el.category_alt_2.toLowerCase().includes(search.toLowerCase())));
				}

				if ( categoryFilter ) {
					return el.main_category && el.main_category.includes(categoryFilter);
				}

				if ( search ) {
					return el.name.toLowerCase().includes(search.toLowerCase()) || (el.main_category && el.main_category.toLowerCase().includes(search.toLowerCase()))  || (el.category_alt && el.category_alt.toLowerCase().includes(search.toLowerCase())) || (el.category_alt_2 && el.category_alt_2.toLowerCase().includes(search.toLowerCase()));
				}

				return el;
			})
			setFilteredCompanies([...temp]);
		}
	}, [search, categoryFilter]);

	return (
		<IonPage>
			<IonHeader className="ion-no-border" translucent="true">
				<IonToolbar>
					<IonTitle size="large" color="primary">{title}</IonTitle>
					<IonButton slot="end" size="small" color="primary" className="btn-go-to-cart btn-go-to-cart-discover" href={TAB_PATH + "/carts"}>
						<IonIcon icon={cartOutline} className="btn-icon-cart"></IonIcon>
					</IonButton>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen="true">
				<IonToast
					color={toast.color}
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={toast.message}
					duration={toast.duration}
				/>
				{ companies && companies.length > 1 ? (
					<>
						<IonSearchbar placeholder="Cerca" animated value={search} onIonChange={e => setSearch(e.detail.value)}></IonSearchbar>
						<div className="filters-container">
							<IonSlides options={slideOpts}>
								<IonSlide className="filter-element" onClick={e => setCategoryFilter('')}>
									<IonButton size="small" className={ categoryFilter === '' ? 'btn-secondary' : 'btn-primary' }>
										Tutte
									</IonButton>
								</IonSlide>
								{ filters && filters.map((mainCategory, index) => (
									<IonSlide className="filter-element" key={index} onClick={e => setCategoryFilter(mainCategory)}>
										<IonButton size="small" className={ mainCategory === categoryFilter ? 'btn-secondary' : 'btn-primary' }>
											{mainCategory}
										</IonButton>
									</IonSlide>
								))}
							</IonSlides>
						</div>
					</>
				) : null }
				<IonRefresher slot="fixed" onIonRefresh={doRefresh} pullFactor={0.5} pullMin={100} pullMax={200}>
					<IonRefresherContent></IonRefresherContent>
				</IonRefresher>
				{ filteredCompanies && filteredCompanies.map((company, index) =>
					(
						<>
							<IonCard href={TAB_PATH + "/companies/" + company.slug} key={index}>
								<div className="company-slide">
									{ company.cover_image && company.logo ? (
										<div className="company-logo-wrapper">
											<IonImg src={process.env.REACT_APP_URL + '/file/' + company.logo} className="company-logo" />
										</div>
									) : null }
									{ company.cover_image ? (
										<IonImg src={process.env.REACT_APP_URL + '/file/' + company.cover_image} className="card-image" />
									) : null }
								</div>
								<IonCardHeader>
									{ company.preferred_company === 'Si' ? (
										<IonCardSubtitle color="medium" className="preferred-company">
											<IonIcon icon={shieldCheckmarkOutline} className="btn-preferred-company"></IonIcon>
											&nbsp;
											La tua attività preferita
										</IonCardSubtitle>
									) : null }
									<IonCardTitle color="primary">{company.name}</IonCardTitle>
									<IonCardSubtitle color="medium">{company.categories ? company.categories : 'Nessuna categoria'}</IonCardSubtitle>
									<div className="card-tags">
										{ company.products && company.payment_info && ( company.payment_info.shipping === 'Si' || company.payment_info.buy_in_store === 'Si' || company.payment_info.takeaway === 'Si' ) ? (
											<IonButton size="small" color="primary" className="btn-primary" href={TAB_PATH + "/companies/" + company.slug + "/products"}>
												Ordina
											</IonButton>
										) : null }
										{ company.services ? (
											<IonButton size="small" color="primary" className="btn-primary" href={TAB_PATH + "/companies/" + company.slug + "/services"}>
												Prenota
											</IonButton>
										) : null }
										{ company.stories ? (
											<IonButton size="small" color="primary" className="btn-primary" href={TAB_PATH + "/companies/" + company.slug + "/stories"}>
												Bacheca
											</IonButton>
										) : null }
										{ company.gift_lists ? (
											<IonButton size="small" color="primary" className="btn-primary" href={process.env.REACT_APP_URL + '/liste-regali/' + company.slug} target="_blank">
												Liste regali
											</IonButton>
										) : null }
									</div>
								</IonCardHeader>
							</IonCard>
							{
								index % 3 === 0 && banners[index] ? (
									<IonCard href={banners[index].url} target="_blank" key={index}>
										<IonImg src={process.env.REACT_APP_URL + '/file/' + banners[index].image} className="company-logo" />
									</IonCard>
								) : null
							}
						</>
					)
				)}
    		</IonContent>
		</IonPage>
	);
};

export default Discover;
