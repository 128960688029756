import { IonGrid, IonRow, IonCol, IonItem, IonLabel, IonInput, IonButton, IonSpinner, IonToast, IonSelect, IonSelectOption, IonSearchbar, IonList, IonModal, IonContent, IonText, IonCheckbox} from '@ionic/react';
import {useEffect, useState} from 'react';
import axiosInstance from '../../../../helpers/axiosInstance';
import handleError from '../../../../helpers/handleError';
import renderHTML from '../../../../helpers/renderHTML';

const RegistationForm = ({form: {onChange, form, onSubmit, untilKeysAreFilled, loading, toastError, setToastError, toastSuccess, setToastSuccess}}) => {

	const [companies, setCompanies] = useState();
	const [companySlug, setCompanySlug] = useState();
	const [companyName, setCompanyName] = useState();
	const [filteredCompanies, setFilteredCompanies] = useState();
	const [showToast, setShowToast] = useState(false);
	const [showAutocomplete, setShowAutocomplete] = useState(false);
	const [selectedCompany, setSelectedCompany] = useState(false);
	const [search, setSearch] = useState('');
	const [checkedTerms, setCheckedTerms] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [termsConditions, setTermsConditions] = useState('');
	const [toast, setToast] = useState({
		color: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
		message: process.env.REACT_APP_DEFAULT_TOAST_MESSAGE,
		duration: process.env.REACT_APP_DEFAULT_TOAST_DURATION
	});

	let getCompanies = () => {
		axiosInstance()
			.get('/companies/summary')
			.then((res) => {
				setCompanies(res.data.data)
				setFilteredCompanies(res.data.data)
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	useEffect(() => {
		getCompanies();
	}, [])

	let getTermsConditions = () => {
		axiosInstance()
			.get('/terms-conditions-register')
			.then((res) => {
				setTermsConditions(res.data)
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	useEffect(() => {
		getTermsConditions();
	}, [])

	useEffect(() => {
		if ( companies ) {
			let temp = companies.filter(el => {
				return el.name.toLowerCase().includes(search.toLowerCase())
			})
			setFilteredCompanies([...temp]);
			if ( search ) {
				setShowAutocomplete(true);
			} else {
				setShowAutocomplete(false);
				setCompanySlug('');
				setCompanyName('');
			}
		}
	}, [search])

	useEffect(() => {
		setShowAutocomplete(false);
		setCompanySlug(selectedCompany.slug);
		setCompanyName(selectedCompany.name);
	}, [selectedCompany])

	let onCheckChange = (e) => {
		setCheckedTerms(!checkedTerms);

		onChange(e);
	}

	return (
		<IonGrid className="register-grid">
			{ companies ? (
				<>
					<IonRow>
						<IonCol>
							<IonSearchbar className="ion-no-padding" placeholder="Seleziona la tua attività preferita" animated value={search} onIonChange={e => setSearch(e.detail.value)} debounce="500"></IonSearchbar>
							{ showAutocomplete ? (
								<IonList>
									{ filteredCompanies && filteredCompanies.map((company, index) => (
										<IonItem className="ion-no-padding" key={index} button onClick={e => setSelectedCompany(company)}>{company.name}</IonItem>
									))}
								</IonList>
							) : null }
						</IonCol>
					</IonRow>
					{ companyName ? (
						<IonRow>
							<IonCol>
								<IonItem className="ion-no-padding">
									<IonLabel position="floating">Attività</IonLabel>
									<IonInput
										value={companyName || ''}
										name="company_name"
										readonly
									></IonInput>
								</IonItem>
							</IonCol>
						</IonRow>
					) : null }
					<IonInput
						className="hidden"
						value={companySlug || ''}
						onIonChange={onChange}
						name="company_slug"
					></IonInput>
				</>
			) : null }
			<IonRow>
				<IonCol>
					<IonItem className="ion-no-padding">
						<IonLabel position="floating">Nome</IonLabel>
						<IonInput
							value={form.first_name || ''}
							onIonChange={onChange}
							name="first_name"
						></IonInput>
					</IonItem>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol>
					<IonItem className="ion-no-padding">
						<IonLabel position="floating">Cognome</IonLabel>
						<IonInput
							value={form.last_name || ''}
							onIonChange={onChange}
							name="last_name"
						></IonInput>
					</IonItem>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol>
					<IonItem className="ion-no-padding">
						<IonLabel position="floating">Email</IonLabel>
						<IonInput
							value={form.email || ''}
							onIonChange={onChange}
							type="email"
							name="email"
						></IonInput>
					</IonItem>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol>
					<IonItem className="ion-no-padding">
						<IonLabel position="floating">Telefono</IonLabel>
						<IonInput
							value={form.phone || ''}
							onIonChange={onChange}
							name="phone"
						></IonInput>
					</IonItem>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol>
					<IonItem className="ion-no-padding">
						<IonLabel position="floating">Password</IonLabel>
						<IonInput
							value={form.password || ''}
							onIonChange={onChange}
							type="password"
							name="password"
						></IonInput>
					</IonItem>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol>
					<IonItem className="ion-no-padding">
						<IonLabel position="floating">Conferma password</IonLabel>
						<IonInput
							value={form.password_confirmation || ''}
							onIonChange={onChange}
							type="password"
							name="password_confirmation"
						></IonInput>
					</IonItem>
				</IonCol>
			</IonRow>
			<br />
			{/* Termini e condizioni */}
			<IonRow>
				<IonCol>
					<IonItem className="checkbox terms-checkbox">
						<IonLabel>Accetto i termini e condizioni</IonLabel>
						<IonCheckbox name="terms_and_conditions" onIonChange={onCheckChange} value={checkedTerms} />
					</IonItem>
					<IonModal isOpen={showModal}>
						<IonContent>
							<IonItem className="terms-text">
								{termsConditions ? renderHTML(termsConditions) : null}
							</IonItem>
						</IonContent>
							<IonButton expand="full" className="ion-no-margin" onClick={() => setShowModal(false)}>Chiudi</IonButton>
					</IonModal>
					<IonText className="terms-link" onClick={() => setShowModal(true)}>
						Leggi i termini e le condizioni
					</IonText>
				</IonCol>
			</IonRow>
			<br />
			<IonRow>
				<IonCol>
					<IonButton expand="full" disabled={untilKeysAreFilled || loading} onClick={onSubmit} className="ion-no-margin">
						{
							!loading
							? 'Registrati'
							: <IonSpinner paused={!loading} />
						}
					</IonButton>
					<IonToast
						color={process.env.REACT_APP_DEFAULT_TOAST_COLOR}
						isOpen={toastError.length > 0 ? true : false}
						onDidDismiss={() => setToastError('')}
						message={toastError}
						duration={3000}
					  />
					<IonToast
						color={'success'}
						isOpen={toastSuccess.length > 0 ? true : false}
						onDidDismiss={() => setToastSuccess('')}
						message={toastSuccess}
						duration={3000}
					  />
				</IonCol>
			</IonRow>
		</IonGrid>
	)
};

export default RegistationForm;
