import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonPage, IonSelect, IonSelectOption, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import middlewareAuth from '../../../middleware/middleware-auth';
import TAB_PATH from '../../../helpers/tabPath';
import { arrowBackOutline } from 'ionicons/icons';
import axiosInstance from '../../../helpers/axiosInstance';
import { useHistory, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import handleError from '../../../helpers/handleError';

const Address = ({title}) => {

	middlewareAuth();

	let history = useHistory();
	const { addressId } = useParams();
	const [form, setForm] = useState({
		type: '',
		billing_type: '',
		name: '',
		company: '',
		tax_code: '',
		vat_number: '',
		pec: '',
		ei_code: '',
		phone_number: '',
		address: '',
		city: '',
		zip_code: '',
		province: '',
		country: '',
		notes: ''
	});
	const [hideInv, setHideInv] = useState(false);
	const [hideDel, setHideDel] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [toast, setToast] = useState({
		color: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
		message: process.env.REACT_APP_DEFAULT_TOAST_MESSAGE,
		duration: process.env.REACT_APP_DEFAULT_TOAST_DURATION
	});

	let getAddress = () => {
		axiosInstance()
			.get('/addresses/' + addressId)
			.then((res) => {
				const data = res.data.data;

				setForm({...form,
					['type']: data.type,
					['billing_type']: data.billing_type,
					['name']: data.name,
					['company']: data.company,
					['tax_code']: data.tax_code,
					['vat_number']: data.vat_number,
					['pec']: data.pec,
					['ei_code']: data.ei_code,
					['phone_number']: data.phone_number,
					['address']: data.address,
					['city']: data.city,
					['zip_code']: data.zip_code,
					['province']: data.province,
					['country']: data.country,
					['notes']: data.notes
				});
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	const onChange = (e) => {
		setForm({...form, [e.target.name]: e.target.value});
	};

	const onSubmit = () => {
		axiosInstance()
			.post('/addresses/' + addressId, {
				type: form.type,
				billing_type: form.billing_type,
				name: form.name,
				company: form.company,
				tax_code: form.tax_code,
				vat_number: form.vat_number,
				pec: form.pec,
				ei_code: form.ei_code,
				phone_number: form.phone_number,
				address: form.address,
				city: form.city,
				zip_code: form.zip_code,
				province: form.province,
				country: form.country,
				notes: form.notes
			}).then((res) => {
				setToast({...toast,
					['color']: 'success',
					['message']: 'L\'indirizzo è stato modificato.',
				})
				setShowToast(true);

				setTimeout(() => {
					history.push(TAB_PATH + "/profile/addresses");
				}, 3000);
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	};

	const onDelete = () => {
		axiosInstance()
			.post('/addresses/' + addressId + '/delete', {
			}).then((res) => {
				setToast({...toast,
					['color']: 'success',
					['message']: 'L\'indirizzo è stato eliminato.',
				})
				setShowToast(true);

				setTimeout(() => {
					history.push(TAB_PATH + "/profile/addresses");
				}, 3000);
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	};

	useEffect(() => {
		getAddress();
	}, []);

	useEffect(() => {
		form.type === 'Spedizione' ? setHideDel(true) : setHideDel(false);
	}, [form.type]);

	useEffect(() => {
		form.billing_type === 'Privato' ? setHideInv(true) : setHideInv(false);
	}, [form.billing_type]);

	return (
		<IonPage>
			<IonHeader className="ion-no-border" translucent="true">
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton color="secondary" className="toolbar-back-icon" text="" defaultHref={TAB_PATH + "/profile/addresses"} icon={arrowBackOutline} />
					</IonButtons>
					<IonTitle color="secondary" className="toolbar-title-with-icon">{title}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen="true">
				<IonToast
					color={toast.color}
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={toast.message}
					duration={toast.duration}
				/>
      			<IonCard>
					<IonCardHeader>
						<IonCardTitle color="primary">{form.name}</IonCardTitle>
					</IonCardHeader>
					<IonCardContent>
						{ hideDel === false ? (
							<IonItem className="ion-no-padding">
								<IonLabel>Tipo di fatturazione</IonLabel>
								<IonSelect name="billing_type" value={form.billing_type} interface="popover" onIonChange={onChange}>
									<IonSelectOption value="Privato">Privato</IonSelectOption>
									<IonSelectOption value="Azienda">Azienda</IonSelectOption>
								</IonSelect>
							</IonItem>
						) : null }
						<IonItem className="ion-no-padding">
							<IonLabel position="floating" color="medium">Nome</IonLabel>
							<IonInput
								value={form.name}
								onIonChange={onChange}
								type="text"
								name="name"
							></IonInput>
						</IonItem>
						{ hideDel === false && hideInv === false ? (
							<IonItem className="ion-no-padding">
								<IonLabel position="floating" color="medium">Azienda</IonLabel>
								<IonInput
									value={form.company}
									onIonChange={onChange}
									type="text"
									name="company"
								></IonInput>
							</IonItem>
						) : null }
						{ hideDel === false ? (
							<IonItem className="ion-no-padding">
								<IonLabel position="floating" color="medium">Codice fiscale</IonLabel>
								<IonInput
									value={form.tax_code}
									onIonChange={onChange}
									type="text"
									name="tax_code"
								></IonInput>
							</IonItem>
						) : null }
						{ hideDel === false && hideInv === false  ? (
							<IonItem className="ion-no-padding">
								<IonLabel position="floating" color="medium">P. IVA</IonLabel>
								<IonInput
									value={form.vat_number}
									onIonChange={onChange}
									type="text"
									name="vat_number"
								></IonInput>
							</IonItem>
						) : null }
						{ hideDel === false && hideInv === false  ? (
							<IonItem className="ion-no-padding">
								<IonLabel position="floating" color="medium">PEC</IonLabel>
								<IonInput
									value={form.pec}
									onIonChange={onChange}
									type="email"
									name="pec"
								></IonInput>
							</IonItem>
						) : null }
						{ hideDel === false && hideInv === false  ? (
							<IonItem className="ion-no-padding">
								<IonLabel position="floating" color="medium">Codice FI</IonLabel>
								<IonInput
									value={form.ei_code}
									onIonChange={onChange}
									type="text"
									name="ei_code"
								></IonInput>
							</IonItem>
						) : null }
						<IonItem className="ion-no-padding">
							<IonLabel position="floating" color="medium">Numero telefono</IonLabel>
							<IonInput
								value={form.phone_number}
								onIonChange={onChange}
								type="text"
								name="phone_number"
							></IonInput>
						</IonItem>
						<IonItem className="ion-no-padding">
							<IonLabel position="floating" color="medium">Indirizzo</IonLabel>
							<IonInput
								value={form.address}
								onIonChange={onChange}
								type="text"
								name="address"
							></IonInput>
						</IonItem>
						<IonItem className="ion-no-padding">
							<IonLabel position="floating" color="medium">Città</IonLabel>
							<IonInput
								value={form.city}
								onIonChange={onChange}
								type="text"
								name="city"
							></IonInput>
						</IonItem>
						<IonItem className="ion-no-padding">
							<IonLabel position="floating" color="medium">CAP</IonLabel>
							<IonInput
								value={form.zip_code}
								onIonChange={onChange}
								type="text"
								name="zip_code"
							></IonInput>
						</IonItem>
						<IonItem className="ion-no-padding">
							<IonLabel position="floating" color="medium">Provincia</IonLabel>
							<IonInput
								value={form.province}
								onIonChange={onChange}
								type="text"
								name="province"
							></IonInput>
						</IonItem>
						<IonItem className="ion-no-padding">
							<IonLabel position="floating" color="medium">Nazione</IonLabel>
							<IonInput
								value={form.country}
								onIonChange={onChange}
								type="text"
								name="country"
							></IonInput>
						</IonItem>
						<IonItem className="ion-no-padding">
							<IonLabel position="floating" color="medium">Note</IonLabel>
							<IonInput
								value={form.notes}
								onIonChange={onChange}
								type="text"
								name="notes"
							></IonInput>
						</IonItem>
						<br /><br />
						<IonButton expand="full" className="ion-no-margin" onClick={onSubmit}>
							Salva
						</IonButton>
						<br />
						<IonButton expand="full" className="ion-no-margin" color="danger" onClick={onDelete}>
							Elimina
						</IonButton>
					</IonCardContent>
				</IonCard>
    		</IonContent>
		</IonPage>
	);
};

export default Address;
