import { IonCard, IonContent, IonHeader, IonImg, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { Link } from 'react-router-dom';
import LoginContainer from '../../../../containers/public/auth/login';
import middlewarePublic from '../../../../middleware/middleware-public';

const Login = ({title}) => {

	middlewarePublic();

	return (
		<IonPage>
			<IonHeader className="ion-no-border" translucent="true">
				<IonToolbar>
					<IonTitle size="large" color="secondary"></IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen="true">
				<div className="welcome-logo">
					<IonImg src="assets/images/logo.png" />
				</div>
				<div className="welcome-title">
					Benvenuto in SharingApp!
				</div>
				<div className="welcome-subtitle">
					Inserisci le tue credenziali per accedere
				</div>
				<IonCard>
					<LoginContainer />
					<div className="welcome-link">
						<Link to="/reset-password">
							Hai dimenticato la password?
						</Link>
						<br /><br />
						<Link to="/register">
							Non hai ancora un account?<br /> Registrati ora!
						</Link>
					</div>
				</IonCard>
			</IonContent>
		</IonPage>
	);
};

export default Login;
