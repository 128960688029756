import Login from '../pages/public/auth/login/Login';
import Register from '../pages/public/auth/register/Register';
import Discover from '../pages/app/discover/Discover';
import Company from '../pages/app/discover/Company';
import Products from '../pages/app/discover/Products';
import Product from '../pages/app/discover/Product';
import Services from '../pages/app/discover/Services';
import Service from '../pages/app/discover/Service';
import Stories from '../pages/app/discover/Stories';
import Story from '../pages/app/discover/Story';
import Cart from '../pages/app/cart/Cart';
import Carts from '../pages/app/cart/Carts';
import Checkout from '../pages/app/checkout/Checkout';
import Offers from '../pages/app/offers/Offers';
import Offer from '../pages/app/offers/Offer';
import Profile from '../pages/app/profile/Profile';
import ProfileEdit from '../pages/app/profile/ProfileEdit';
import ChangePassword from '../pages/app/profile/ChangePassword';
import DeleteAccount from '../pages/app/profile/DeleteAccount';
import Orders from '../pages/app/profile/Orders';
import Order from '../pages/app/profile/Order';
import Addresses from '../pages/app/profile/Addresses';
import Address from '../pages/app/profile/Address';
import TAB_PATH from '../helpers/tabPath';
import { bagOutline, flameOutline, personOutline } from 'ionicons/icons';
import ResetPassword from '../pages/public/auth/reset-password/ResetPassword';

const routes = {
	public: {
		auth: [
			{
				path: "/",
				component: Login,
				title: "Login",
				auth: false,
			},
			{
				path: "/register",
				component: Register,
				title: "Registrazione",
				auth: false,
			},
			{
				path: "/reset-password",
				component: ResetPassword,
				title: "Recupera lapassword",
				auth: false,
			},
		]
	},
	app: {
		tabs: [
			{
				path: TAB_PATH + "/companies",
				component: Discover,
				title: "Scopri",
				tabName: 'Scopri',
				tabIcon: bagOutline,
				tabLabel: 'Scopri',
				auth: true,
			},
			{
				path: TAB_PATH + "/offers",
				component: Offers,
				title: "Offerte",
				tabName: 'Offerte',
				tabIcon: flameOutline,
				tabLabel: 'Offerte',
				auth: true,
			},
			{
				path: TAB_PATH + "/profile",
				component: Profile,
				title: "Profilo",
				tabName: 'Profilo',
				tabIcon: personOutline,
				tabLabel: 'Profilo',
				auth: true,
			},
		],
		companies: [
			{
				path: TAB_PATH + "/companies/:companySlug",
				component: Company,
				title: "Azienda",
				auth: true,
			},
			{
				path: TAB_PATH + "/companies/:companySlug/products",
				component: Products,
				title: "Prodotti",
				auth: true,
			},
			{
				path: TAB_PATH + "/companies/:companySlug/products/:productSlug",
				component: Product,
				title: "Prodotto",
				auth: true,
			},
			{
				path: TAB_PATH + "/companies/:companySlug/services",
				component: Services,
				title: "Servizi",
				auth: true,
			},
			{
				path: TAB_PATH + "/companies/:companySlug/services/:serviceSlug",
				component: Service,
				title: "Servizio",
				auth: true,
			},
			{
				path: TAB_PATH + "/companies/:companySlug/stories",
				component: Stories,
				title: "Bacheca",
				auth: true,
			},
			{
				path: TAB_PATH + "/companies/:companySlug/stories/:storySlug",
				component: Story,
				title: "Bacheca",
				auth: true,
			},
			{
				path: TAB_PATH + "/companies/:companySlug/cart",
				component: Cart,
				title: "Carrello",
				auth: true,
			},
			{
				path: TAB_PATH + "/companies/:companySlug/checkout",
				component: Checkout,
				title: "Checkout",
				auth: true,
			},
		],
		carts: [
			{
				path: TAB_PATH + "/carts",
				component: Carts,
				title: "Carrelli",
				auth: true,
			},
		],
		offers: [
			{
				path: TAB_PATH + "/offers/:offerSlug",
				component: Offer,
				title: "Offerta",
				auth: true,
			},
		],
		profile: [
			{
				path: TAB_PATH + "/profile/edit",
				component: ProfileEdit,
				title: "Modifica i tuoi dati",
				auth: true,
			},
			{
				path: TAB_PATH + "/profile/change-password",
				component: ChangePassword,
				title: "Cambia la password",
				auth: true,
			},
			{
				path: TAB_PATH + "/profile/delete-account",
				component: DeleteAccount,
				title: "Elimina il tuo account",
				auth: true,
			},
			{
				path: TAB_PATH + "/profile/orders",
				component: Orders,
				title: "Ordini e prenotazioni",
				auth: true,
			},
			{
				path: TAB_PATH + "/profile/orders/:orderId",
				component: Order,
				title: "Il tuo ordine",
				auth: true,
			},
			{
				path: TAB_PATH + "/profile/addresses",
				component: Addresses,
				title: "I tuoi indirizzi",
				auth: true,
			},
			{
				path: TAB_PATH + "/profile/addresses/:addressId",
				component: Address,
				title: "Il tuo indirizzo",
				auth: true,
			}
		]
	}
};

export default routes;
