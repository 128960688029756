import { useHistory, useLocation, useParams } from "react-router";
import isAuthenticated from "../helpers/isAuthenticated";

export default () => {
    const history = useHistory();
	const location = useLocation();

	if ( !isAuthenticated() && location.pathname.indexOf('profile') == -1 ) {
		history.push('/?redirect=' + btoa(location.pathname));
	} else if ( !isAuthenticated() ) {
		history.push('/');
	}
}
