import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCol, IonContent, IonDatetime, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonPage, IonRow, IonSelect, IonSelectOption, IonText, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import middlewareAuth from '../../../middleware/middleware-auth';
import TAB_PATH from '../../../helpers/tabPath';
import { arrowBackOutline, arrowForwardCircleOutline, addCircleOutline, removeCircleOutline, closeCircleOutline } from 'ionicons/icons';
import handleError from '../../../helpers/handleError';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../helpers/axiosInstance';
import { useHistory, useParams } from 'react-router';

const Cart = ({title}) => {

	middlewareAuth();

	const history = useHistory();

	const { companySlug } = useParams()
	const [cart, setCart] = useState([])
	const [amount, setAmount] = useState(localStorage['cart_amount_' + companySlug] || '')
	const [itemsCount, setItemsCount] = useState(localStorage['cart_items_count_' + companySlug] || '')
	const [deliveryMethod, setDeliveryMethod] = useState('')
	const [numTable, setNumTable] = useState('')
	const [notes, setNotes] = useState('')
	const [bookingAvailable, setBookingAvailable] = useState(false)
	const [bookingEnable, setBookingEnable] = useState(false)
	const [showToast, setShowToast] = useState(false)
	const [toast, setToast] = useState({
		color: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
		message: process.env.REACT_APP_DEFAULT_TOAST_MESSAGE,
		duration: process.env.REACT_APP_DEFAULT_TOAST_DURATION
	})

	const [dates, setDates] = useState([])
	const [slots, setSlots] = useState([])
	const [selectedSlots, setSelectedSlots] = useState([])
	const [date, setDate] = useState('')
	const [selectedSlot, setSelectedSlot] = useState('')

	let getSlots = () => {
		axiosInstance()
			.get('/availability/order/' + companySlug)
			.then((res) => {
				setDates(Object.keys(res.data))
				setSlots(res.data)
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	let onDatesChange = (e) => {
		setSelectedSlots(slots[e.detail.value])
		setDate(e.detail.value)
	}

	let onSlotChange = (e) => {
		setSelectedSlot(e.detail.value)
	}

	let getCart = () => {
		axiosInstance()
			.get('/cart/' + companySlug)
			.then((res) => {
				if ( res.data.data.length === 0 ) {
					setCart('')
				} else {
					setCart(res.data.data)
				}
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	const addItem = (e) => {
		e.preventDefault();

		let detail = e.target.detail;
		let type = e.target.type;

		if ( detail.item.manage_stock === 'Si' && detail.item.stock == 0 ) {
			setToast({...toast,
				['color']: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
				['message']: 'Spiacente, il prodotto non è più disponibile.',
			})
			setShowToast(true);

			return;
		}

		axiosInstance()
			.post('/cart/increment', {
				items: [
					{
						company_id: detail.item.company_id,
						type: type,
						id: detail.item.id
					}
				]
			}).then((res) => {
				setAmount(res.data.data.amount)
				setItemsCount(res.data.data.items_count)
				getCart();
				setToast({...toast,
					['color']: 'success',
					['message']: 'L\'elemento è stato aggiunto al carrello.',
				})
				setShowToast(true);
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	};

	const subItem = (e) => {
		e.preventDefault();

		let detail = e.target.detail;
		let type = e.target.type;

		if ( detail.quantity == 1 ) {
			setToast({...toast,
				['color']: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
				['message']: 'Hai raggiunto il numero minimo di elementi acquistabili. Se desideri puoi rimuovere il prodotto.',
			})
			setShowToast(true);

			return;
		}

		if ( detail.item.manage_stock === 'Si' && detail.item.stock == 0 ) {
			setToast({...toast,
				['color']: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
				['message']: 'Spiacente, il prodotto non è più disponibile.',
			})
			setShowToast(true);

			return;
		}

		axiosInstance()
			.post('/cart/decrement', {
				items: [
					{
						company_id: detail.item.company_id,
						type: type,
						id: detail.item.id
					}
				]
			}).then((res) => {
				setAmount(res.data.data.amount)
				setItemsCount(res.data.data.items_count)
				getCart();
				setToast({...toast,
					['color']: 'success',
					['message']: 'L\'elemento è stato rimosso dal carrello.',
				})
				setShowToast(true);
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	};

	const deleteItem = (e) => {
		e.preventDefault();

		let detail = e.target.detail;
		let type = e.target.type;

		axiosInstance()
			.post('/cart/delete-detail', {
				items: [
					{
						id: detail.item.id,
						type: type,
						company_id: detail.item.company_id,
					}
				]
			}).then((res) => {
				setAmount(res.data.data.amount)
				setItemsCount(res.data.data.items_count)
				getCart();
				setToast({...toast,
					['color']: 'success',
					['message']: 'L\'elemento è stato rimosso dal carrello.',
				})
				setShowToast(true);
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	};

	const goBack = () => {
		history.goBack()
	}

	const goToCheckout = (e) => {
		axiosInstance()
			.post('/cart/' + companySlug + '/update',
				{
					delivery_method: deliveryMethod ? deliveryMethod : cart.delivery_method,
					num_table: numTable ? numTable : cart.num_table,
					notes: notes ? notes : cart.notes,
					delivery_datetime: date.length > 0 && selectedSlot.length > 0 ? date + ' ' + selectedSlot + ':00' : '',
					amount_check: amount,
				}
			).then((res) => {
				if ( amount === '0,00' ) {
					setToast({...toast,
						['color']: 'success',
						['message']: 'Grazie, la tua prenotazione è stata inviata!',
					})
					setShowToast(true);
					localStorage.removeItem('cart_amount_' + companySlug);
					localStorage.removeItem('cart_items_count_' + companySlug);
					setTimeout(() => {
						history.push(TAB_PATH + '/' + res.data.redirect);
					}, 4000);
				} else {
					history.push(TAB_PATH + '/' + res.data.redirect);
				}
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	useEffect(() => {
		getCart();
		getSlots();
	}, []);

	useEffect(() => {
		localStorage['cart_amount_' + companySlug] = typeof amount !== 'undefined' ? amount : '';
	}, [amount]);

	useEffect(() => {
		localStorage['cart_items_count_' + companySlug] = typeof itemsCount !== 'undefined' ? itemsCount : '';
	}, [itemsCount]);

	useEffect(() => {
		if ( cart.shipping_mode ) {
			let bookingAvailable = Object.values(cart.shipping_mode).filter(el => {
				return el.booking === true;
			});

			if ( bookingAvailable.length > 0 ) {
				setBookingAvailable(true)
			} else {
				setBookingAvailable(false)
				// setDeliveryDatetime('')
			}
		}
	}, [cart.shipping_mode]);

	const onChangeDeliveryMethodSelect = (e) => {
		if (cart) {
			setDeliveryMethod(e.detail.value.data);

			if (e.detail.value.booking == true ) {
				setBookingEnable(true)
			} else {
				setBookingEnable(false)
				// setDeliveryDatetime('')
			}
		}
	};

	return (
		<IonPage>
			<IonHeader className="ion-no-border" translucent="true">
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton color="secondary" className="toolbar-back-icon" text="" defaultHref="" onClick={goBack} icon={arrowBackOutline} />
					</IonButtons>
					<IonTitle color="secondary" className="toolbar-title-with-icon">{title}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen="true">
				<IonToast
					color={toast.color}
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={toast.message}
					duration={toast.duration}
				/>
				{ cart ? (
					<IonCard>
						<IonCardHeader>
							<IonCardSubtitle color="primary">Il tuo carrello {cart.company_name}</IonCardSubtitle>
						</IonCardHeader>
						<IonCardContent>
							<div className="list-cart-items">
								{cart.details && cart.details.map((detail, index) => (
									<div className="list-cart-item" key={index}>
										<IonText color="primary" className="cart-price">
											{ detail.price ? detail.currency_code + ' ' + detail.price : null }
										</IonText>
										<IonText color="primary" className="cart-item">
											{ detail.name ? detail.name : null }
											{ detail.booking_datetime ? (
												<>
													<br />
													<IonText color="primary" className="cart-item-booking">
														Prenotato il {detail.booking_datetime}
													</IonText>
												</>
											) : null }
										</IonText>
										<br />
										<IonGrid className="ion-no-padding">
											<IonRow className="ion-no-padding">
												<IonCol size="5" className="ion-no-padding">
													{ detail.type === 'Product' ? (
														<IonItem className="cart-quantity">
															<IonLabel color="medium">Quantità</IonLabel>
															<IonInput type="number" value={parseInt(detail.quantity)} readonly></IonInput>
														</IonItem>
													) : null }
												</IonCol>
												<IonCol size="7" className="ion-no-padding">
													<IonButton className="btn-remove-from-cart" detail={detail} type={detail.type} onClick={deleteItem}>
														<IonIcon icon={closeCircleOutline}></IonIcon>
													</IonButton>
													{ detail.type === 'Product' ? (
														<>
															<IonButton className="btn-change-quantity-cart" detail={detail} type={detail.type} onClick={subItem}>
																<IonIcon icon={removeCircleOutline}></IonIcon>
															</IonButton>
															<IonButton className="btn-change-quantity-cart" detail={detail} type={detail.type} onClick={addItem}>
																<IonIcon icon={addCircleOutline}></IonIcon>
															</IonButton>
														</>
													 ) : null }
												</IonCol>
											</IonRow>
										</IonGrid>
									</div>
								))}
							</div>
							{ cart && amount !== '0,00' ? (
								<>
									<IonCardSubtitle color="primary">Modalità di consegna</IonCardSubtitle>
									<IonSelect
										interfaceOptions={{
											header: 'Scegli come desideri ricevere il tuo ordine',
											subHeader: '',
											cssClass: 'custom-action-sheet'
										}}
										interface="action-sheet"
										cancelText="Annulla"
										placeholder="Scegli una modalità di consegna"
										onIonChange={onChangeDeliveryMethodSelect}
									>
										{cart && cart.shipping_mode && cart.shipping_mode.buy_in_store ? (
											<IonSelectOption value={cart.shipping_mode.buy_in_store}>
												{ cart.shipping_mode.buy_in_store.label }
											</IonSelectOption>
										) : null }
										{cart && cart.shipping_mode && cart.shipping_mode.takeaway ? (
											<IonSelectOption value={cart.shipping_mode.takeaway}>
												{ cart.shipping_mode.takeaway.label }
											</IonSelectOption>
										) : null }
										{cart && cart.shipping_mode && cart.shipping_mode.shipping ? (
											<IonSelectOption value={cart.shipping_mode.shipping}>
												{ cart.shipping_mode.shipping.label }
											</IonSelectOption>
										) : null }
									</IonSelect>
									<br />
								</>
							) : null }
							{ cart.details && bookingAvailable && bookingEnable ? (
								<>
									<IonCardSubtitle color="primary">
										{ deliveryMethod === 'takeaway' ? (
											'Quando desideri effettuare il ritiro'
										) : (
											'Quando desideri ricevere l’ordine'
										) }
									</IonCardSubtitle>
									<IonSelect
										interfaceOptions={{
											header: 'Scegli una data',
											subHeader: '',
											cssClass: 'custom-action-sheet'
										}}
										interface="action-sheet"
										cancelText="Annulla"
										placeholder="Scegli una data"
										onDatesChange
										onIonChange={onDatesChange}
									>
										{dates.map((date, index) => (
											<IonSelectOption value={date} key={index}>
												{ date }
											</IonSelectOption>
										))}
									</IonSelect>
									{ selectedSlots && selectedSlots.length > 0 ? (
										<IonSelect
											interfaceOptions={{
												header: 'Scegli un orario',
												subHeader: '',
												cssClass: 'custom-action-sheet'
											}}
											interface="action-sheet"
											cancelText="Annulla"
											placeholder="Scegli un orario"
											onIonChange={onSlotChange}
										>
											{selectedSlots.map((slot, index) => (
												<IonSelectOption value={slot} key={index}>
													{ slot }
												</IonSelectOption>
											))}
										</IonSelect>
									) : null }
									<br />
								</>
							) : null }
							{ cart && cart.details && cart.ask_location === 'Si' && amount !== '0,00' && !bookingEnable ? (
								<IonItem className="cart-input">
									<IonLabel color="medium">N. tavolo/postazione</IonLabel>
									<IonInput
										type="text"
										onIonChange={e => setNumTable(e.detail.value)}
										value={numTable ? numTable : cart.num_table}
									></IonInput>
								</IonItem>
							) : null }
							<IonItem className="cart-input">
								<IonLabel color="medium">Note</IonLabel>
								<IonInput type="text" onIonChange={e => setNotes(e.detail.value)} value={notes ? notes : cart.notes}></IonInput>
							</IonItem>
							<br />
							<IonItem className="cart-quantity">
								<IonLabel color="medium">Totale</IonLabel>
								<IonInput type="text" value={'€ ' + localStorage['cart_amount_' + companySlug]} readonly></IonInput>
							</IonItem>
							<br />
							{ cart && cart.min_order_amount !== '0,00' && deliveryMethod === 'shipping' ? (
								<IonText color="medium">Per ordini con consegna a domicilio è richiesto un minimo d'ordine di {cart.currency_code + ' ' + cart.min_order_amount}. </IonText>
							) : null }
							{ cart && cart.min_delivery_amount !== '0,00' && deliveryMethod === 'shipping' ? (
								<IonText color="medium">La consegna gratuita è prevista per un ordine di almeno {cart.currency_code + ' ' + cart.min_delivery_amount}. </IonText>
							) : null }
							<br />
							<br />
							<IonButton expand="full" className="ion-no-margin" onClick={goToCheckout}>
								{ amount === '0,00' ? (
									'Invia la prenotazione!'
								) : (
									'Vai alla cassa'
								) }
							</IonButton>
						</IonCardContent>
					</IonCard>
				) :
					<IonCard>
						<IonCardContent>
							<IonCardSubtitle color="medium">Non hai inserito elementi nel carrello.</IonCardSubtitle>
							<br />
							<IonButton color="primary" className="btn-primary" onClick={goBack}>
								Torna indietro
							</IonButton>
						</IonCardContent>
					</IonCard>
				}
				{ cart && cart.open_carts && cart.open_carts.length > 0 ? (
					<IonCard>
						<IonCardHeader>
							<IonCardSubtitle color="medium">Hai degli altri carrelli attivi, completali!</IonCardSubtitle>
						</IonCardHeader>
						<IonCardContent>
							<div className="list-other-carts">
								{ cart.open_carts.map((item, index) => (
									<div className="list-other-cart" key={index}>
										<IonText color="primary" className="cart-title">{item.company.name}</IonText>
										<IonButton className="btn-go-to-other-cart" href={TAB_PATH + "/companies/" + item.company.slug + "/cart"}>
											<IonIcon icon={arrowForwardCircleOutline}></IonIcon>
										</IonButton>
									</div>
								))}
							</div>
						</IonCardContent>
					</IonCard>
				) : null }
			</IonContent>
		</IonPage>
	);
};

export default Cart;
