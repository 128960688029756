import { IonGrid, IonRow, IonCol, IonItem, IonLabel, IonInput, IonButton, IonSpinner, IonToast} from '@ionic/react';
import {useState} from 'react';

const LoginForm = ({form: {onChange, form, onSubmit, untilKeysAreFilled, loading, toastError, setToastError}}) => {

	return (
		<IonGrid className="login-grid">
			<IonRow>
				<IonCol>
					<IonItem className="ion-no-padding">
						<IonLabel position="floating">Email</IonLabel>
						<IonInput
							value={form.email || ''}
							onIonChange={onChange}
							type="email"
							name="email"
						></IonInput>
					</IonItem>
				</IonCol>
			</IonRow>
			<IonRow>
				<IonCol>
					<IonItem className="ion-no-padding">
						<IonLabel position="floating">Password</IonLabel>
						<IonInput
							value={form.password || ''}
							onIonChange={onChange}
							type="password"
							name="password"
						></IonInput>
					</IonItem>
				</IonCol>
			</IonRow>
			<br />
			<IonRow>
				<IonCol>
					<IonButton expand="full" disabled={untilKeysAreFilled || loading} onClick={onSubmit} className="ion-no-margin">
						{
							!loading
							? 'Accedi'
							: <IonSpinner paused={!loading} />
						}
					</IonButton>
					<IonToast
						color={process.env.REACT_APP_DEFAULT_TOAST_COLOR}
						isOpen={toastError.length > 0 ? true : false}
						onDidDismiss={() => setToastError('')}
						message={toastError}
						duration={3000}
					  />
				</IonCol>
			</IonRow>
		</IonGrid>
	)
};

export default LoginForm;
