import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToast, IonToolbar, isPlatform } from '@ionic/react';
import middlewareAuth from '../../../middleware/middleware-auth';
import TAB_PATH from '../../../helpers/tabPath';
import { arrowForwardOutline, heartDislikeOutline, heartOutline, lockClosedOutline, logOutOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../helpers/axiosInstance';
import handleError from '../../../helpers/handleError';
import { AppIcon } from '@capacitor-community/app-icon';

const Profile = ({title}) => {

	middlewareAuth();

	const [user, setUser] = useState([]);
	const [appIcon, setAppIcon] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [toast, setToast] = useState({
		color: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
		message: process.env.REACT_APP_DEFAULT_TOAST_MESSAGE,
		duration: process.env.REACT_APP_DEFAULT_TOAST_DURATION
	});

	useEffect(() => {
		axiosInstance()
			.get('/my-profile')
			.then((res) => {
				setUser(res.data.data)
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}, [])

	useEffect(() => {
		if ( isPlatform('ios') && !isPlatform('mobileweb') && !isPlatform('desktop') ) {
			getIcon();
		}
	}, [])

	const getIcon = async () => {
		const currentIcon = await AppIcon.getName();
		const appIconIsSet = currentIcon.value !== null ? true : false;
			setAppIcon(appIconIsSet);
	}

	const setIcon = async () => {
		const isSupported = await AppIcon.isSupported();

		if (user && user.company && isSupported.value) {
			const preferredCompanySlug = user.company.slug;
			// const preferredCompanySlug = 'company';

			await AppIcon.change({name: preferredCompanySlug, suppressNotification: false});
			setAppIcon(true);
		}
	}

	const resetIcon = async () => {
		localStorage.appIconResetted = true;
		await AppIcon.reset({suppressNotification: false});
		setAppIcon(false);
	}

	const logout = () => {
		if ( !localStorage.tokenPushPlugin ) {
			localStorage.token = '';
			window.location.reload();
		} else {
			axiosInstance()
				.post('/logout', {
					token_push_notifications: localStorage.tokenPushPlugin
				}).then((res) => {
					localStorage.token = '';
					localStorage.tokenPushPlugin = '';
					window.location.reload();
				})
				.catch((err) => {
					handleError(err, toast, setToast, setShowToast)
				});
		}
	}

	return (
		<IonPage>
			<IonHeader className="ion-no-border" translucent="true">
				<IonToolbar>
					<IonTitle size="large" color="primary">{title}</IonTitle>
					<IonButton slot="end" size="small" color="primary" className="btn-logout" onClick={logout}>
						<IonIcon icon={logOutOutline} className="btn-icon-white"></IonIcon>
						&nbsp;
						Esci
					</IonButton>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen="true">
				<IonToast
					color={toast.color}
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={toast.message}
					duration={toast.duration}
				/>
      			<IonCard>
				  	<IonCardHeader>
						<br />
						<IonCardTitle color="primary">Ciao {user.first_name}!</IonCardTitle>
						<IonCardSubtitle color="medium">Benvenuto nel tuo profilo.</IonCardSubtitle>
					</IonCardHeader>
					<IonCardContent>
						<br />
						<IonCardSubtitle color="medium" className="subtitle-before-btn">I tuoi acquisti</IonCardSubtitle>
						<IonButton color="primary" className="btn-primary" href={TAB_PATH + "/profile/orders"}>
							Ordini e prenotazioni
							&nbsp;
							<IonIcon icon={arrowForwardOutline} className="btn-icon-white"></IonIcon>
						</IonButton>
						<br />
						<IonButton color="primary" className="btn-primary" href={TAB_PATH + "/profile/addresses"}>
							I tuoi indirizzi
							&nbsp;
							<IonIcon icon={arrowForwardOutline} className="btn-icon-white"></IonIcon>
						</IonButton>
						<br /><br />
						<IonCardSubtitle color="medium" className="subtitle-before-btn">I tuoi dati</IonCardSubtitle>
						<IonButton color="primary" className="btn-primary" href={TAB_PATH + "/profile/edit"}>
							Modifica i tuoi dati
							&nbsp;
							<IonIcon icon={arrowForwardOutline} className="btn-icon-white"></IonIcon>
						</IonButton>
						<br />
						<IonButton color="primary" className="btn-primary" href={TAB_PATH + "/profile/change-password"}>
							Cambia la password
							&nbsp;
							<IonIcon icon={arrowForwardOutline} className="btn-icon-white"></IonIcon>
						</IonButton>
						<br />
						<IonButton color="primary" className="btn-primary" href={TAB_PATH + "/profile/delete-account"}>
							Elimina il tuo account
							&nbsp;
							<IonIcon icon={arrowForwardOutline} className="btn-icon-white"></IonIcon>
						</IonButton>
						<br /><br />
						<IonButton size="small" className="btn-secondary" href={process.env.REACT_APP_URL} target="_blank">
							<IonIcon icon={lockClosedOutline} className="btn-icon-blue"></IonIcon>
							&nbsp;
							Area riservata
						</IonButton>
						<br /><br />
						{ user && user.company && isPlatform('ios') && !isPlatform('mobileweb') && !isPlatform('desktop') ? (
							<>
								{ appIcon ? (
									<IonButton size="small" className="btn-secondary" onClick={resetIcon}>
										<IonIcon icon={heartDislikeOutline} className="btn-icon-blue"></IonIcon>
										&nbsp;
										Ripristina l'icona dell'app
									</IonButton>
								) : (
									<IonButton size="small" className="btn-secondary" onClick={setIcon}>
										<IonIcon icon={heartOutline} className="btn-icon-blue"></IonIcon>
										&nbsp;
										Imposta l'icona di {user.company.name}
									</IonButton>
								) }
							</>
						) : null }
					</IonCardContent>
				</IonCard>
    		</IonContent>
		</IonPage>
	);
};

export default Profile;
