import {REGISTER_LOADING, REGISTER_SUCCESS, REGISTER_ERROR, LOGIN_LOADING, LOGIN_SUCCESS, LOGIN_ERROR} from '../../constants/actionTypes';

const auth = (state, {type, payload}) => {
	switch(type) {
		case REGISTER_LOADING:
			return {
				...state,
				auth: {
					...state.auth,
					registerErrors: false,
					loading: true,
				}
			}
		case LOGIN_LOADING:
			return {
				...state,
				auth: {
					...state.auth,
					loginErrors: false,
					loading: true,
				}
			}

		case REGISTER_SUCCESS:
		case LOGIN_SUCCESS:
			return {
				...state,
				auth: {
					...state.auth,
					loading: false,
					data: payload,
				}
			}

		case REGISTER_ERROR:
			return {
				...state,
				auth: {
					...state.auth,
					loading: false,
					registerErrors: payload,
				}
			}
		case LOGIN_ERROR:
			return {
				...state,
				auth: {
					...state.auth,
					loading: false,
					loginErrors: payload,
				}
			}

		default:
			return state;
	}
}

export default auth;
