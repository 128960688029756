import { IonBackButton, IonButton, IonButtons, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonPage, IonRefresher, IonRefresherContent, IonRow, IonSearchbar, IonSlide, IonSlides, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import middlewareAuth from '../../../middleware/middleware-auth';
import TAB_PATH from '../../../helpers/tabPath';
import { arrowBackOutline, addCircleOutline, cartOutline } from 'ionicons/icons';
import { useParams } from 'react-router';
import axiosInstance from '../../../helpers/axiosInstance';
import { useEffect, useState } from 'react';
import handleError from '../../../helpers/handleError';
import getQueryString from '../../../helpers/getQueryString';
import pluckUnique from '../../../helpers/pluckUnique';

const Products = ({title}) => {

	middlewareAuth();

	let category = getQueryString(window, 'category');

	const slideOpts = {
		slidesPerView: 'auto',
		zoom: false,
		grabCursor: true,
	};

	const { companySlug } = useParams()
	const [products, setProducts] = useState([])
	const [filteredProducts, setFilteredProducts] = useState([])
	const [filters, setFilters] = useState([])
	const [search, setSearch] = useState('')
	const [categoryFilter, setCategoryFilter] = useState('')
	const [amount, setAmount] = useState(localStorage['cart_amount_' + companySlug] || '')
	const [itemsCount, setItemsCount] = useState(localStorage['cart_items_count_' + companySlug] || '')
	const [addedToCart, setAddedToCart] = useState(localStorage['cart_items_count_' + companySlug] > 0 ? true : false)
	const [showToast, setShowToast] = useState(false)
	const [toast, setToast] = useState({
		color: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
		message: process.env.REACT_APP_DEFAULT_TOAST_MESSAGE,
		duration: process.env.REACT_APP_DEFAULT_TOAST_DURATION
	})

	let getProducts = () => {
		axiosInstance()
			.get('/products/' + companySlug)
			.then((res) => {
				setProducts(res.data.data)
				setFilteredProducts(res.data.data);
				setFilters(pluckUnique(res.data.data, 'category').sort((a, b) => a.localeCompare(b)))
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	let doRefresh = (event) => {
		getProducts();
		setSearch('');
		setCategoryFilter('');

		setTimeout(() => {
			event.detail.complete();
		}, 2000);
	}

	const addItem = (e) => {
		e.preventDefault();

		let product = e.target.product;

		if ( product.manage_stock === 'Si' && product.stock == 0 ) {
			setToast({...toast,
				['color']: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
				['message']: 'Spiacente, questo prodotto non è al momento disponibile',
			})
			setShowToast(true);

			return;
		}

		axiosInstance()
			.post('/cart/add', {
				items: [
					{
						company_id: product.company_id,
						type: 'Product',
						id: product.id,
						title: product.title,
						price: product.sale_price !== "0,00" ? product.sale_price : product.price
					}
				]
			}).then((res) => {
				setAmount(res.data.data.amount)
				setItemsCount(res.data.data.items_count)
				setToast({...toast,
					['color']: 'success',
					['message']: 'Il prodotto è stato aggiunto al carrello.',
				})
				setShowToast(true);

				setAddedToCart(true);
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	};

	useEffect(() => {
		getProducts();
	}, []);

	useEffect(() => {
		if ( products ) {
			let temp = products.filter(el => {
				if ( categoryFilter && search ) {
					return el.category && el.category.includes(categoryFilter) && el.title.toLowerCase().includes(search.toLowerCase());
				}

				if ( categoryFilter ) {
					return el.category && el.category.includes(categoryFilter);
				}

				if ( search ) {
					return el.title.toLowerCase().includes(search.toLowerCase());
				}

				return el;
			})
			setFilteredProducts([...temp]);
		}
	}, [search, categoryFilter]);

	useEffect(() => {
		localStorage['cart_amount_' + companySlug] = amount;
	}, [amount]);

	useEffect(() => {
		localStorage['cart_items_count_' + companySlug] = itemsCount;
	}, [itemsCount]);

	return (
		<IonPage>
			<IonHeader className="ion-no-border" translucent="true">
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton color="secondary" className="toolbar-back-icon" text="" defaultHref={TAB_PATH + "/companies/" + companySlug} icon={arrowBackOutline} />
					</IonButtons>
					<IonTitle color="secondary" className="toolbar-title-with-icon">{title}</IonTitle>
					{ products && products[0] ? (
						<IonButton slot="end" size="small" color={addedToCart === false ? 'primary' : 'success'} className="btn-go-to-cart" href={TAB_PATH + "/companies/" + companySlug + "/cart"}>
							{localStorage['cart_items_count_' + companySlug] ? localStorage['cart_items_count_' + companySlug] : null}
							<IonIcon icon={cartOutline} className="btn-icon-cart"></IonIcon>
							{localStorage['cart_amount_' + companySlug] && localStorage['cart_amount_' + companySlug] !== '0,00' ? products[0].currency_code + ' ' + localStorage['cart_amount_' + companySlug] : null}
						</IonButton>
					) : null }
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen="true">
				<IonToast
					color={toast.color}
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={toast.message}
					duration={toast.duration}
				/>
				{ products && products[0] ? (
					<IonCardSubtitle className="company-name-title" color="primary">
						I prodotti di {products[0].company_name}
					</IonCardSubtitle>
				) : null }
				<IonSearchbar placeholder="Cerca" animated value={search} onIonChange={e => setSearch(e.detail.value)}></IonSearchbar>
				<div className="filters-container">
					<IonSlides options={slideOpts}>
						<IonSlide className="filter-element" onClick={e => setCategoryFilter('')}>
							<IonButton size="small" className={ categoryFilter === '' ? 'btn-secondary' : 'btn-primary' }>
								Tutti
							</IonButton>
						</IonSlide>
						{ filters && filters.map((category, index) => (
							<IonSlide className="filter-element" key={index} onClick={e => setCategoryFilter(category)}>
								<IonButton size="small" className={ category === categoryFilter ? 'btn-secondary' : 'btn-primary' }>
									{category}
								</IonButton>
							</IonSlide>
						))}
					</IonSlides>
				</div>
				<IonRefresher slot="fixed" onIonRefresh={doRefresh} pullFactor={0.5} pullMin={100} pullMax={200}>
					<IonRefresherContent></IonRefresherContent>
				</IonRefresher>
				<IonGrid>
					<IonRow>
						{ filteredProducts && filteredProducts.map((product, index) => (
							<IonCol size="6" size-md="4" size-lg="4" key={index}>
								<IonCard href={TAB_PATH + "/companies/" + product.company_slug + "/products/" + product.slug} className="ion-no-margin">
									{ product.slide_image_1 ? (
										<IonImg className="products-card-image" src={process.env.REACT_APP_URL + '/file/' + product.slide_image_1} />
									) : null }
									<IonCardHeader>
										<IonCardTitle color="primary" className="product-title">{product.title}</IonCardTitle>
										<IonButton className="btn-add-to-cart" product={product} onClick={addItem}>
											<IonIcon icon={addCircleOutline}></IonIcon>
										</IonButton>
										<IonCardSubtitle color="primary" className="product-price">{product.sale_price !== "0,00" ? product.currency_code + ' ' + product.sale_price : product.currency_code + ' ' + product.price}</IonCardSubtitle>
									</IonCardHeader>
								</IonCard>
							</IonCol>
						))}
					</IonRow>
				</IonGrid>
    		</IonContent>
		</IonPage>
	);
};

export default Products;
