import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonImg, IonPage, IonRefresher, IonRefresherContent, IonSearchbar, IonSlide, IonSlides, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import middlewareAuth from '../../../middleware/middleware-auth';
import TAB_PATH from '../../../helpers/tabPath';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../helpers/axiosInstance';
import handleError from '../../../helpers/handleError';
import renderHTML from '../../../helpers/renderHTML';
import getQueryString from '../../../helpers/getQueryString';
import pluckUnique from '../../../helpers/pluckUnique';

const Offers = ({title}) => {

  	middlewareAuth();

	let category = getQueryString(window, 'category');

	const slideOpts = {
		slidesPerView: 'auto',
		zoom: false,
		grabCursor: true,
	};

	const [offers, setOffers] = useState();
	const [filteredOffers, setFilteredOffers] = useState();
	const [filters, setFilters] = useState([]);
	const [search, setSearch] = useState('');
	const [categoryFilter, setCategoryFilter] = useState('');
	const [showToast, setShowToast] = useState(false);
	const [toast, setToast] = useState({
		color: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
		message: process.env.REACT_APP_DEFAULT_TOAST_MESSAGE,
		duration: process.env.REACT_APP_DEFAULT_TOAST_DURATION
	});

	let getOffers = () => {
		axiosInstance()
			.get('/offers')
			.then((res) => {
				setOffers(res.data.data)
				setFilteredOffers(res.data.data)
				setFilters(pluckUnique(res.data.data, 'company.main_category').sort((a, b) => a.localeCompare(b)))
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	let doRefresh = (event) => {
		getOffers();
		setSearch('');
		setCategoryFilter('');

		setTimeout(() => {
			event.detail.complete();
		}, 2000);
	}

	useEffect(() => {
		getOffers();
	}, []);

	useEffect(() => {
		if ( offers ) {
			let temp = offers.filter(el => {
				if ( categoryFilter && search ) {
					return el.company.main_category && el.company.main_category.includes(categoryFilter) && el.title.toLowerCase().includes(search.toLowerCase());
				}

				if ( categoryFilter ) {
					return el.company.main_category && el.company.main_category.includes(categoryFilter);
				}

				if ( search ) {
					return el.title.toLowerCase().includes(search.toLowerCase());
				}

				return el;
			})
			setFilteredOffers([...temp]);
		}
	}, [search, categoryFilter]);

  	return (
		<IonPage>
			<IonHeader className="ion-no-border" translucent="true">
				<IonToolbar>
					<IonTitle size="large" color="secondary">{title}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen="true">
				<IonToast
					color={toast.color}
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={toast.message}
					duration={toast.duration}
				/>
				<IonSearchbar placeholder="Cerca" animated value={search} onIonChange={e => setSearch(e.detail.value)}></IonSearchbar>
				<div className="filters-container">
					<IonSlides options={slideOpts}>
						<IonSlide className="filter-element" onClick={e => setCategoryFilter('')}>
							<IonButton size="small" className={ categoryFilter === '' ? 'btn-secondary' : 'btn-primary' }>
								Tutte
							</IonButton>
						</IonSlide>
						{ filters && filters.map((category, index) => (
							<IonSlide className="filter-element" key={index} onClick={e => setCategoryFilter(category)}>
								<IonButton size="small" className={ category === categoryFilter ? 'btn-secondary' : 'btn-primary' }>
									{category}
								</IonButton>
							</IonSlide>
						))}
					</IonSlides>
				</div>
				<IonRefresher slot="fixed" onIonRefresh={doRefresh} pullFactor={0.5} pullMin={100} pullMax={200}>
					<IonRefresherContent></IonRefresherContent>
				</IonRefresher>
				{ filteredOffers && filteredOffers.map((offer, index) => (
					<IonCard href={TAB_PATH + "/offers/" + offer.slug} key={index}>
						<div className="card-image-wrapper">
							{ offer.promo && offer.image ? (
								<div className="card-promo">{offer.promo}</div>
							) : null }
							{ offer.image ? (
								<IonImg className="card-image" src={process.env.REACT_APP_URL + '/file/' + offer.image} />
							) : null }
						</div>
						<IonCardHeader>
							<IonCardTitle color="primary">{offer.title}</IonCardTitle>
							{ offer.company ? (
								<IonCardSubtitle color="medium">{offer.company.name}</IonCardSubtitle>
							) : null }
							<div className="card-tags">
								{ offer.product ? (
									<IonButton size="small" color="primary" className="btn-primary" href={TAB_PATH + "/companies/" + offer.product.company_slug + "/products/" + offer.product.slug}>
										Scopri il prodotto
									</IonButton>
								) : null }
								{ offer.service ? (
									<IonButton size="small" color="primary" className="btn-primary" href={TAB_PATH + "/companies/" + offer.service.company_slug + "/services/" + offer.service.slug}>
										Scopri il servizio
									</IonButton>
								) : null }
							</div>
						</IonCardHeader>
						{/* <IonCardContent>
							<div className="ion-text-justify">
								{renderHTML(offer.excerpt)}
							</div>
						</IonCardContent> */}
					</IonCard>
				))}
    		</IonContent>
		</IonPage>
	);
};

export default Offers;
