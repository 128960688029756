import {createContext, useReducer, ReactNode} from 'react'
import auth from './reducer/auth';
import authInitialState from './initial-states/authInitialState';

export const GlobalContext = createContext({});

type Props = {
	children: ReactNode,
};

export const GlobalProvider = (props: Props) => {
	
	const [authState, authDispatch] = useReducer(auth, authInitialState);
	
	return (
		<GlobalContext.Provider
		value={{
			authState,
			authDispatch
		}}>
			{props.children}
		</GlobalContext.Provider>
	);
};
