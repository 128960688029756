import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonImg, IonPage, IonSlide, IonSlides, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import middlewareAuth from '../../../middleware/middleware-auth';
import TAB_PATH from '../../../helpers/tabPath';
import { arrowBackOutline, addCircleOutline, cartOutline } from 'ionicons/icons';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../helpers/axiosInstance';
import handleError from '../../../helpers/handleError';
import renderHTML from '../../../helpers/renderHTML';

const Product = ({title}) => {

	middlewareAuth();

	const slideOpts = {
		autoplay: true,
		loop: true,
		slidesPerView: 1,
		speed: 1000,
		delay: 5000,
	};

	const { companySlug, productSlug } = useParams()
	const [product, setProduct] = useState([])
	const [amount, setAmount] = useState(localStorage['cart_amount_' + companySlug] || '')
	const [itemsCount, setItemsCount] = useState(localStorage['cart_items_count_' + companySlug] || '')
	const [addedToCart, setAddedToCart] = useState(localStorage['cart_items_count_' + companySlug] > 0 ? true : false)
	const [showToast, setShowToast] = useState(false)
	const [toast, setToast] = useState({
		color: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
		message: process.env.REACT_APP_DEFAULT_TOAST_MESSAGE,
		duration: process.env.REACT_APP_DEFAULT_TOAST_DURATION
	})

	let getProduct = () => {
		axiosInstance()
			.get('/products/' + companySlug + '/' + productSlug)
			.then((res) => {
				setProduct(res.data.data)
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	const addItem = (e) => {
		e.preventDefault();

		if ( product.manage_stock === 'Si' && product.stock == 0 ) {
			setToast({...toast,
				['color']: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
				['message']: 'Spiacente, questo prodotto non è al momento disponibile.',
			})
			setShowToast(true);

			return;
		}

		axiosInstance()
			.post('/cart/add', {
				items: [
					{
						company_id: product.company_id,
						type: 'Product',
						id: product.id,
						title: product.title,
						price: product.sale_price !== "0,00" ? product.sale_price : product.price
					}
				]
			}).then((res) => {
				setAmount(res.data.data.amount)
				setItemsCount(res.data.data.items_count)
				setToast({...toast,
					['color']: 'success',
					['message']: 'Il prodotto è stato aggiunto al carrello.',
				})
				setShowToast(true);

				setAddedToCart(true);
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	};

	useEffect(() => {
		getProduct();
	}, []);

	useEffect(() => {
		localStorage['cart_amount_' + companySlug] = amount;
	}, [amount]);

	useEffect(() => {
		localStorage['cart_items_count_' + companySlug] = itemsCount;
	}, [itemsCount]);

	return (
		<IonPage>
			<IonHeader className="ion-no-border" translucent="true">
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton color="secondary" className="toolbar-back-icon" text="" defaultHref={TAB_PATH + "/companies/" + companySlug + "/products"} icon={arrowBackOutline} />
					</IonButtons>
					<IonTitle color="secondary" className="toolbar-title-with-icon">{title}</IonTitle>
					{ product ? (
						<IonButton slot="end" size="small" color={addedToCart === false ? 'primary' : 'success'} className="btn-go-to-cart" href={TAB_PATH + "/companies/" + companySlug + "/cart"}>
							{localStorage['cart_items_count_' + companySlug] ? localStorage['cart_items_count_' + companySlug] : null}
							<IonIcon icon={cartOutline} className="btn-icon-cart"></IonIcon>
							{localStorage['cart_amount_' + companySlug] && localStorage['cart_amount_' + companySlug] !== '0,00' ? product.currency_code + ' ' + localStorage['cart_amount_' + companySlug] : null}
						</IonButton>
					) : null }
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen="true">
				<IonToast
					color={toast.color}
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={toast.message}
					duration={toast.duration}
				/>
				<IonCard>
					{/* SLIDES */}
					{ product.slide_image_1 && !product.slide_image_2 && !product.slide_image_3 ? (
						<IonImg className="product-card-image" src={process.env.REACT_APP_URL + '/file/' + product.slide_image_1} />
					) : (
						<IonSlides options={slideOpts} pager={true}>
							{ product.slide_image_1 ? (
								<IonSlide>
									<IonImg className="product-card-image" src={process.env.REACT_APP_URL + '/file/' + product.slide_image_1} />
								</IonSlide>
							) : null }
							{ product.slide_image_2 ? (
								<IonSlide>
									<IonImg className="product-card-image" src={process.env.REACT_APP_URL + '/file/' + product.slide_image_2} />
								</IonSlide>
							) : null }
							{ product.slide_image_3 ? (
								<IonSlide>
									<IonImg className="product-card-image" src={process.env.REACT_APP_URL + '/file/' + product.slide_image_3} />
								</IonSlide>
							) : null }
						</IonSlides>
					) }
					<IonCardHeader>
						<IonCardTitle color="primary">{product.title}</IonCardTitle>
						<IonCardSubtitle color="medium">{product.company_name}</IonCardSubtitle>
						<IonCardSubtitle color="medium">{product.category}</IonCardSubtitle>
					</IonCardHeader>
					<IonCardContent>
						<div className="ion-text-justify">
							{renderHTML(product.text)}
						</div>
						<br />
						{ product.manage_stock === 'Si'  ? (
							<IonCardSubtitle color="medium">Disponibilità: {product.stock}</IonCardSubtitle>
						) : null }
						{ product.low_availability === 'Si' && product.manage_stock === 'Si' && product.stock > 0  ? (
							<IonCardSubtitle color="danger">In esaurimento!</IonCardSubtitle>
						) : null }
						{ product.manage_stock === 'Si'  ? (
							<br />
						) : null }
						<IonCardSubtitle color="medium" className="subtitle-before-btn">Aggiungi al carrello</IonCardSubtitle>
						<IonButton className="btn-add-to-cart-single" onClick={addItem}>
							<IonIcon icon={addCircleOutline}></IonIcon>
						</IonButton>
						<IonCardSubtitle color="primary" className="product-price">{product.sale_price !== "0,00" ? product.currency_code + ' ' + product.sale_price : product.currency_code + ' ' + product.price}</IonCardSubtitle>
					</IonCardContent>
				</IonCard>
    		</IonContent>
		</IonPage>
	);
};

export default Product;
