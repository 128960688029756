import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonImg, IonPage, IonSlide, IonSlides, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import middlewareAuth from '../../../middleware/middleware-auth';
import TAB_PATH from '../../../helpers/tabPath';
import { arrowBackOutline, mapOutline, callOutline, atOutline, cartOutline, chatbubblesOutline, giftOutline } from 'ionicons/icons';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../helpers/axiosInstance';
import handleError from '../../../helpers/handleError';

const Company = ({title}) => {

	middlewareAuth();

	const slideOpts = {
		autoplay: true,
		loop: true,
		slidesPerView: 1,
		speed: 1000,
		delay: 5000,
	};

	const { companySlug } = useParams()
	const [company, setCompany] = useState([])
	const [addedToCart, setAddedToCart] = useState(localStorage['cart_items_count_' + companySlug] > 0 ? true : false)
	const [showToast, setShowToast] = useState(false)
	const [showWH, setShowWH] = useState(false)
	const [showWHException, setShowWHException] = useState(false)
	const [posShowWH, setPosShowWH] = useState(false)
	const [posShowWHException, setPosShowWHException] = useState(false)
	const [toast, setToast] = useState({
		color: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
		message: process.env.REACT_APP_DEFAULT_TOAST_MESSAGE,
		duration: process.env.REACT_APP_DEFAULT_TOAST_DURATION
	})

	let getCompany = () => {
		axiosInstance()
			.get('/companies/' + companySlug)
			.then((res) => {
				setCompany(res.data.data)
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	useEffect(() => {
		getCompany();
	}, []);

	return (
		<IonPage>
			<IonHeader className="ion-no-border" translucent="true">
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton color="secondary" className="toolbar-back-icon" text="" defaultHref={TAB_PATH + "/companies"} icon={arrowBackOutline} />
					</IonButtons>
					<IonTitle color="secondary" className="toolbar-title-with-icon">{title}</IonTitle>
					{ company && company.payment_info ? (
						<IonButton slot="end" size="small" color={addedToCart === false ? 'primary' : 'success'} className="btn-go-to-cart" href={TAB_PATH + "/companies/" + companySlug + "/cart"}>
							{localStorage['cart_items_count_' + companySlug] ? localStorage['cart_items_count_' + companySlug] : null}
							<IonIcon icon={cartOutline} className="btn-icon-cart"></IonIcon>
							{localStorage['cart_amount_' + companySlug] && localStorage['cart_amount_' + companySlug] !== '0,00' ? company.payment_info.currency_code + ' ' + localStorage['cart_amount_' + companySlug] : null}
						</IonButton>
					) : null }
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen="true">
				<IonToast
					color={toast.color}
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={toast.message}
					duration={toast.duration}
				/>
				<IonCard>
					{/* SLIDES */}
					<div className="company-slide">
						{ company.logo && ( company.cover_image || company.slide_image1 || company.slide_image2 || company.slide_image3 ) ? (
							<div className="company-logo-wrapper">
								<IonImg src={process.env.REACT_APP_URL + '/file/' + company.logo} className="company-logo" />
							</div>
						) : null }
						{ company.cover_image && (!company.slide_image1 || !company.slide_image2 || !company.slide_image3) ? (
							<IonImg src={process.env.REACT_APP_URL + '/file/' + company.cover_image} className="card-image" />
						) : (
							<IonSlides options={slideOpts} pager={true}>
								{ company.slide_image1 ? (
									<IonSlide>
										<IonImg src={process.env.REACT_APP_URL + '/file/' + company.slide_image1} className="card-image" />
									</IonSlide>
								) : null }
								{ company.slide_image2 ? (
									<IonSlide>
										<IonImg src={process.env.REACT_APP_URL + '/file/' + company.slide_image2} className="card-image" />
									</IonSlide>
								) : null }
								{ company.slide_image3 ? (
									<IonSlide>
										<IonImg src={process.env.REACT_APP_URL + '/file/' + company.slide_image3} className="card-image" />
									</IonSlide>
								) : null }
							</IonSlides>
						) }
					</div>
					{/* MAIN DATA */}
						<IonCardHeader>
							<IonCardTitle color="primary">{company.name}</IonCardTitle>
							<IonCardSubtitle color="medium">{company.categories}</IonCardSubtitle>
						</IonCardHeader>
						<IonCardContent>
							{ company.products || company.services || company.gift_lists ? (
								<IonCardSubtitle color="medium" className="subtitle-before-btn">I servizi per te</IonCardSubtitle>
							) : null }
							{ company.products && company.payment_info && ( company.payment_info.shipping === 'Si' || company.payment_info.buy_in_store === 'Si' || company.payment_info.takeaway === 'Si' ) ? (
								<IonButton color="primary" className="btn-primary" href={TAB_PATH + "/companies/" + companySlug + "/products"}>
									Ordina
								</IonButton>
							) : null }
							{ company.services ? (
								<IonButton color="primary" className="btn-primary" href={TAB_PATH + "/companies/" + companySlug + "/services"}>
									Prenota
								</IonButton>
							) : null }
							{ company.stories ? (
								<IonButton color="primary" className="btn-primary" href={TAB_PATH + "/companies/" + companySlug + "/stories"}>
									Bacheca
								</IonButton>
							) : null }
							{ company.gift_lists ? (
								<IonButton className="btn-secondary" href={process.env.REACT_APP_URL + '/liste-regali/' + company.slug} target="_blank">
									<IonIcon icon={giftOutline} className="btn-icon-blue"></IonIcon>
									&nbsp;
									Liste regali
								</IonButton>
							) : null }
							<br /><br />
							{ company.description ? (
								<>
									<IonCardSubtitle color="medium">Di cosa si tratta</IonCardSubtitle>
									<p className="ion-text-justify">
										{company.description}
									</p>
									<br />
								</>
							) : null }
							{ company.website || ( company.links && company.links.length > 0 ) ? (
								<IonCardSubtitle color="medium" className="subtitle-before-btn">Scopri di più</IonCardSubtitle>
							) : null }
							{ company.website ? (
								<IonButton size="small" className="btn-secondary" href={company.website} target="_blank">
									Sito web
								</IonButton>
							) : null }
							{ company.links && company.links.length > 0 && company.links.map((link, index) => (
								<IonButton size="small" className="btn-secondary" href={link.url} target="_blank" key={index}>
									{link.name}
								</IonButton>
							))}
							{ company.products && company.payment_info && company.payment_info.shipping === 'Si' && ( company.payment_info.min_order_amount !== '0,00' || company.payment_info.min_delivery_amount !== '0,00' ) ? (
								<>
									<br /><br />
									<IonCardSubtitle color="medium">Vuoi fare un ordine con consegna a domicilio?</IonCardSubtitle>
									<p className="ion-text-justify">
										{ company.payment_info && company.payment_info.min_order_amount !== '0,00' ? (
											<>
												È richiesto un minimo d'ordine di {company.payment_info.currency_code + ' ' + company.payment_info.min_order_amount}.&nbsp;
											</>
										) : null }
										{ company.payment_info && company.payment_info.min_delivery_amount !== '0,00' ? (
											<>
												La consegna gratuita è prevista per un ordine di almeno {company.payment_info.currency_code + ' ' + company.payment_info.min_delivery_amount}.
											</>
										) : null }
									</p>
								</>
							) : null }
						</IonCardContent>
					</IonCard>
				{/* MAIN CONTACTS */}
					<IonCard>
						<IonCardHeader>
							<IonCardTitle color="medium">Sede</IonCardTitle>
						</IonCardHeader>
						<IonCardContent>
							<IonCardSubtitle color="medium" className="subtitle-before-btn">Contatti</IonCardSubtitle>
							{company.address && company.city ? (
								<IonButton size="small" className="btn-secondary" href={encodeURI('https://www.google.com/maps/search/?api=1&query=' + company.address + ' ' + company.city)} target="_blank">
									<IonIcon icon={mapOutline} className="btn-icon-blue"></IonIcon>
									&nbsp;
									{company.address} {company.city} ({company.province})
								</IonButton>
							) : null }
							{ company.whatsapp_phone ? (
								<>
									<br />
									<IonButton size="small" className="btn-secondary" href={'https://wa.me/' + company.whatsapp_phone.replace(/\s/g, '')}>
										<IonIcon icon={chatbubblesOutline} className="btn-icon-blue"></IonIcon>
										&nbsp;
										WhatsApp
									</IonButton>
								</>
							) : null }
							{ company.phone_number ? (
								<>
									<br />
									<IonButton size="small" className="btn-secondary" href={'tel:' + company.phone_number}>
										<IonIcon icon={callOutline} className="btn-icon-blue"></IonIcon>
										&nbsp;
										Chiama
									</IonButton>
								</>
							) : null }
							{ company.email ? (
								<>
									<br />
									<IonButton size="small" className="btn-secondary" href={'mailto:' + company.email}>
										<IonIcon icon={atOutline} className="btn-icon-blue"></IonIcon>
										&nbsp;
										Scrivi
									</IonButton>
								</>
							) : null }
							<br /><br />
							{/* WH TODAY */}
								{ company.working_hours_today ? (
									<IonCardSubtitle color="medium" className="subtitle-before-btn">Orario di oggi</IonCardSubtitle>
								) : null }
								{ company.working_hours_today ? (
									<div>
										<ion-text color="dark">
											{company.working_hours_today.day}:
											{ company.working_hours_today.status !== 'Aperto' ? (
												<>
													&nbsp; {company.working_hours_today.status}
												</>
											) : (
												<>
													&nbsp; {company.working_hours_today.from_hour_0} - {company.working_hours_today.to_hour_0}, {company.working_hours_today.from_hour_1} - {company.working_hours_today.to_hour_1}
												</>
											)}
										</ion-text>
										<br />
									</div>
								) : null }
							<br />
							{/* WH EXCEPTION TODAY */}
							{ company.working_hours_exceptions_today ? (
								<IonCardSubtitle color="medium" className="subtitle-before-btn">Oggi è prevista una chiusura straordinaria!</IonCardSubtitle>
							) : null }
							{ company.working_hours_exceptions_today ? (
								<div>
									<ion-text color="dark">
										Dalle {company.working_hours_exceptions_today.from_hour} alle {company.working_hours_exceptions_today.to_hour}:
									</ion-text> {company.working_hours_exceptions_today.status}
									<br /><br />
								</div>
							) : null }
							{/* ALL WH */}
								{ company.working_hours && company.working_hours.length > 0 && showWH ? (
									<IonCardSubtitle color="medium" className="subtitle-before-btn">Tutti gli orari</IonCardSubtitle>
								) : null }
								{ company.working_hours && company.working_hours.length > 0 && !showWH ? (
									<IonButton size="small" className="btn-secondary" onClick={() => setShowWH(true)}>
										Mostra tutti gli orari
									</IonButton>
								) : null }
								{ company.working_hours && company.working_hours.length > 0 && showWH && company.working_hours.map((wh, index) => (
									<div key={index}>
										<ion-text color="dark">
											{wh.day}:
											{ wh.status !== 'Aperto' ? (
												<>
													&nbsp; {wh.status}
												</>
											) : (
												<>
													&nbsp; {wh.from_hour_0} - {wh.to_hour_0}, {wh.from_hour_1} - {wh.to_hour_1}
												</>
											)}
										</ion-text>
										<br />
									</div>
								))}
							{/* ALL WH EXCEPTIONS */}
								<br />
								{ company.working_hours_exceptions && company.working_hours_exceptions.length > 0 && showWHException ? (
									<IonCardSubtitle color="medium" className="subtitle-before-btn">Chiusure straordinarie</IonCardSubtitle>
								) : null }
								{ company.working_hours_exceptions && company.working_hours_exceptions.length > 0 && !showWHException ? (
									<IonButton size="small" className="btn-secondary" onClick={() => setShowWHException(true)}>
										Mostra tutte le chiusure straordinarie
									</IonButton>
								) : null }
								{ company.working_hours_exceptions && company.working_hours_exceptions.length > 0 && showWHException && company.working_hours_exceptions.map((whE, index) => (
									<div key={index}>
										<ion-text color="dark">
											Dal {whE.from_date} al {whE.to_date}, {whE.from_hour} - {whE.to_hour}:
										</ion-text> {whE.status}
									</div>
								))}
						</IonCardContent>
					</IonCard>
				{/* POS */}
					{ company.pos && company.pos.length > 0 && company.pos.map((pos, index) => (
						<IonCard key={index}>
							<IonCardHeader>
								<IonCardTitle color="medium">{pos.name}</IonCardTitle>
							</IonCardHeader>
							<IonCardContent>
								<IonCardSubtitle color="medium" className="subtitle-before-btn">Contatti</IonCardSubtitle>
								{pos.address && pos.city ? (
									<>
										<IonButton size="small" className="btn-secondary" href={encodeURI('https://www.google.com/maps/search/?api=1&query=' + pos.address + ' ' + pos.city)} target="_blank">
											<IonIcon icon={mapOutline} className="btn-icon-blue"></IonIcon>
											&nbsp;
											{pos.address} {pos.city} ({pos.province})
										</IonButton>
										<br />
									</>
								) : null }
								{ pos.phone_number ? (
									<>
										<IonButton size="small" className="btn-secondary" href={'tel:' + pos.phone_number}>
											<IonIcon icon={callOutline} className="btn-icon-blue"></IonIcon>
											&nbsp;
											Chiama
										</IonButton>
										<br />
									</>
								) : null }
								{ pos.email ? (
									<>
										<IonButton size="small" className="btn-secondary" href={'mailto:' + pos.email}>
											<IonIcon icon={atOutline} className="btn-icon-blue"></IonIcon>
											&nbsp;
											Scrivi
										</IonButton>
										<br />
									</>
								) : null }
								<br />
								{/* WH TODAY */}
									{ pos.working_hours_today ? (
										<IonCardSubtitle color="medium" className="subtitle-before-btn">Orario di oggi</IonCardSubtitle>
									) : null }
									{ pos.working_hours_today ? (
										<div>
											<ion-text color="dark">
												{pos.working_hours_today.day}:
												{ pos.working_hours_today.status !== 'Aperto' ? (
													<>
														&nbsp; {pos.working_hours_today.status}
													</>
												) : (
													<>
														&nbsp; {pos.working_hours_today.from_hour_0} - {pos.working_hours_today.to_hour_0}, {pos.working_hours_today.from_hour_1} - {pos.working_hours_today.to_hour_1}
													</>
												)}
											</ion-text>
											<br /><br />
										</div>
									) : null }
								{/* WH EXCEPTION TODAY */}
									{ pos.working_hours_exceptions_today ? (
										<IonCardSubtitle color="medium" className="subtitle-before-btn">Oggi è prevista una chiusura straordinaria!</IonCardSubtitle>
									) : null }
									{ pos.working_hours_exceptions_today ? (
										<div>
											<ion-text color="dark">
												Dalle {pos.working_hours_exceptions_today.from_hour} alle {pos.working_hours_exceptions_today.to_hour}:
											</ion-text> {pos.working_hours_exceptions_today.status}
											<br /><br />
										</div>
									) : null}
								{/* ALL WH */}
									{ pos.working_hours && pos.working_hours.length > 0 && posShowWH ? (
										<IonCardSubtitle color="medium" className="subtitle-before-btn">Tutti gli orari</IonCardSubtitle>
									) : null }
									{ pos.working_hours && pos.working_hours.length > 0 && !posShowWH ? (
										<IonButton size="small" className="btn-secondary" onClick={() => setPosShowWH(true)}>
											Mostra tutti gli orari
										</IonButton>
									) : null }
									{ pos.working_hours && pos.working_hours.length > 0 && posShowWH && pos.working_hours.map((wh, index) => (
										<div key={index}>
											<ion-text color="dark">
												{wh.day}:
												{ wh.status !== 'Aperto' ? (
													<>
														&nbsp; {wh.status}
													</>
												) : (
													<>
														&nbsp; {wh.from_hour_0} - {wh.to_hour_0}, {wh.from_hour_1} - {wh.to_hour_1}
													</>
												)}
											</ion-text>
											<br />
										</div>
									))}
								{/* ALL WH EXCEPTION */}
									<br />
									{ pos.working_hours_exceptions && pos.working_hours_exceptions.length > 0 && posShowWHException ? (
										<IonCardSubtitle color="medium" className="subtitle-before-btn">Chiusure straordinarie</IonCardSubtitle>
									) : null }
									{ pos.working_hours_exceptions && pos.working_hours_exceptions.length > 0 && !posShowWHException ? (
										<IonButton size="small" className="btn-secondary" onClick={() => setPosShowWHException(true)}>
											Mostra tutte le chiusure straordinarie
										</IonButton>
									) : null }
									{ pos.working_hours_exceptions && pos.working_hours_exceptions.length > 0 && posShowWHException && pos.working_hours_exceptions.map((whE, index) => (
										<div key={index}>
											<ion-text color="dark">
												Dal {whE.from_date} al {whE.to_date}, {whE.from_hour} - {whE.to_hour}:
											</ion-text> {whE.status}
											<br />
										</div>
									))}
							</IonCardContent>
						</IonCard>
					))}
    		</IonContent>
		</IonPage>
	);
};

export default Company;
