import axios from "axios";

export default (history = null) => {

	let headers = {
		Authorization: '',
		Accept: 'application/json',
	};

	if ( localStorage.token ) {
		headers.Authorization = "Bearer " + localStorage.token;
	}

	const axiosInstance = axios.create({
		baseURL: process.env.REACT_APP_API_URL,
		headers
	});

	axiosInstance.interceptors.response.use(
		(response) =>
			new Promise((resolve, reject) => {
				resolve(response);
			}),
			(error) => {
				let defaultToastMessage = process.env.REACT_APP_DEFAULT_TOAST_MESSAGE

				if ( !error.response ) {
					let err = {
						errors: [
							{
								0: defaultToastMessage
							}
						]
					}

					return new Promise((resolve, reject) => {
						reject(err);
					});
				}

				if ( error.response.status === 401 ) {
					localStorage.token = '';
					window.location.reload();
				} else if ( error.response.status === 422 ) {
					return new Promise((resolve, reject) => {
						reject(error.response.data);
					});
				} else {
					let err = {
						errors: [
							{
								0: defaultToastMessage
							}
						]
					}

					return new Promise((resolve, reject) => {
						reject(err);
					});
				}
			}
		);

	return axiosInstance;
};
