import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonContent, IonHeader, IonList, IonPage, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import middlewareAuth from '../../../middleware/middleware-auth';
import TAB_PATH from '../../../helpers/tabPath';
import { arrowBackOutline } from 'ionicons/icons';
import axiosInstance from '../../../helpers/axiosInstance';
import { useState } from 'react';
import handleError from '../../../helpers/handleError';
import { useHistory } from 'react-router';

const DeleteAccount = ({title}) => {

	middlewareAuth();

	const history = useHistory();
	const [showToast, setShowToast] = useState(false);
	const [toast, setToast] = useState({
		color: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
		message: process.env.REACT_APP_DEFAULT_TOAST_MESSAGE,
		duration: process.env.REACT_APP_DEFAULT_TOAST_DURATION
	});

	const onSubmit = () => {
		axiosInstance()
			.post('/delete-account')
			.then((res) => {
				setToast({...toast,
					['color']: 'success',
					['message']: 'Operazione avvenuta con successo.',
				})
				setShowToast(true);

				setTimeout(() => {
					history.push(TAB_PATH + "/profile");
				}, 4000);
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	};

  return (
		<IonPage>
			<IonHeader className="ion-no-border" translucent="true">
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton color="secondary" className="toolbar-back-icon" text="" defaultHref={TAB_PATH + "/profile"} icon={arrowBackOutline} />
					</IonButtons>
					<IonTitle color="secondary" className="toolbar-title-with-icon">{title}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen="true">
				<IonToast
					color={toast.color}
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={toast.message}
					duration={toast.duration}
				/>
				<IonCard>
					<IonCardHeader>
						<IonCardSubtitle color="medium">Qui puoi richiedere l’eliminazione definitiva del tuo account. L’operazione comporta la rimozione dei tuoi dati e ordini effettuati.</IonCardSubtitle>
					</IonCardHeader>
					<IonCardContent>
						<IonList>
							<IonButton expand="full" color="danger" className="ion-no-margin" onClick={onSubmit}>
								Elimina l'account
							</IonButton>
						</IonList>
					</IonCardContent>
				</IonCard>
    		</IonContent>
		</IonPage>
	);
};

export default DeleteAccount;
