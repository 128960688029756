const pluckUnique = (object, field) => {
    let uniqueData = [];

    if ( field.indexOf('.') > -1  ) {
        let fields = field.split('.');

        Object.values(object).map(value => {
            if (value[fields[0]][fields[1]] && uniqueData.indexOf(value[fields[0]][fields[1]]) === -1) {
                uniqueData.push(value[fields[0]][fields[1]])
            }
        });

        return uniqueData;
    }

    Object.values(object).map(value => {
        if (value[field] && uniqueData.indexOf(value[field]) === -1) {
            uniqueData.push(value[field])
        }
    });

    return uniqueData;
}

export default pluckUnique;
