import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonPage, IonRefresher, IonRefresherContent, IonRow, IonSearchbar, IonSlide, IonSlides, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import middlewareAuth from '../../../middleware/middleware-auth';
import TAB_PATH from '../../../helpers/tabPath';
import { arrowBackOutline, cartOutline } from 'ionicons/icons';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../helpers/axiosInstance';
import handleError from '../../../helpers/handleError';
import getQueryString from '../../../helpers/getQueryString';
import pluckUnique from '../../../helpers/pluckUnique';
import renderHTML from '../../../helpers/renderHTML';

const Stories = ({title}) => {

  	middlewareAuth();

	let category = getQueryString(window, 'category');

	const { companySlug } = useParams();
	const slideOpts = {
		slidesPerView: 'auto',
		zoom: false,
		grabCursor: true,
	};
	const [stories, setStories] = useState();
	const [filteredStories, setFilteredStories] = useState();
	const [filters, setFilters] = useState([]);
	const [search, setSearch] = useState('');
	const [categoryFilter, setCategoryFilter] = useState('');
	const [addedToCart, setAddedToCart] = useState(localStorage['cart_items_count_' + companySlug] > 0 ? true : false)
	const [showToast, setShowToast] = useState(false);
	const [toast, setToast] = useState({
		color: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
		message: process.env.REACT_APP_DEFAULT_TOAST_MESSAGE,
		duration: process.env.REACT_APP_DEFAULT_TOAST_DURATION
	});

	let getStories = () => {
		axiosInstance()
			.get('/stories/' + companySlug)
			.then((res) => {
				setStories(res.data.data)
				setFilteredStories(res.data.data)
				setFilters(pluckUnique(res.data.data, 'category').sort((a, b) => a.localeCompare(b)))
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	let doRefresh = (event) => {
		getStories();
		setSearch('');
		setCategoryFilter('');

		setTimeout(() => {
			event.detail.complete();
		}, 2000);
	}

	useEffect(() => {
		getStories();
	}, []);

	useEffect(() => {
		if ( stories ) {
			let temp = stories.filter(el => {
				if ( categoryFilter && search ) {
					return el.category && el.category.includes(categoryFilter) && el.title.toLowerCase().includes(search.toLowerCase());
				}

				if ( categoryFilter ) {
					return el.category && el.category.includes(categoryFilter);
				}

				if ( search ) {
					return el.title.toLowerCase().includes(search.toLowerCase());
				}

				return el;
			})
			setFilteredStories([...temp]);
		}
	}, [search, categoryFilter]);

  	return (
		<IonPage>
			<IonHeader className="ion-no-border" translucent="true">
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton color="secondary" className="toolbar-back-icon" text="" defaultHref={TAB_PATH + "/companies/" + companySlug} icon={arrowBackOutline} />
					</IonButtons>
					<IonTitle color="secondary" className="toolbar-title-with-icon">{title}</IonTitle>
					{ stories && stories[0] ? (
						<IonButton slot="end" size="small" color={addedToCart === false ? 'primary' : 'success'} className="btn-go-to-cart" href={TAB_PATH + "/companies/" + companySlug + "/cart"}>
							{localStorage['cart_items_count_' + companySlug] ? localStorage['cart_items_count_' + companySlug] : null}
							<IonIcon icon={cartOutline} className="btn-icon-cart"></IonIcon>
							{localStorage['cart_amount_' + companySlug] && localStorage['cart_amount_' + companySlug] !== '0,00' ? stories[0].currency_code + ' ' + localStorage['cart_amount_' + companySlug] : null}
						</IonButton>
					) : null }
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen="true">
				<IonToast
					color={toast.color}
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={toast.message}
					duration={toast.duration}
				/>
				{ stories && stories[0] ? (
					<IonCardSubtitle className="company-name-title" color="primary">
						La bacheca di {stories[0].company_name}
					</IonCardSubtitle>
				) : null }
				<IonSearchbar placeholder="Cerca" animated value={search} onIonChange={e => setSearch(e.detail.value)}></IonSearchbar>
				<div className="filters-container">
					<IonSlides options={slideOpts}>
						<IonSlide className="filter-element" onClick={e => setCategoryFilter('')}>
							<IonButton size="small" className={ categoryFilter === '' ? 'btn-secondary' : 'btn-primary' }>
								Tutte
							</IonButton>
						</IonSlide>
						{ filters && filters.map((category, index) => (
							<IonSlide className="filter-element" key={index} onClick={e => setCategoryFilter(category)}>
								<IonButton size="small" className={ category === categoryFilter ? 'btn-secondary' : 'btn-primary' }>
									{category}
								</IonButton>
							</IonSlide>
						))}
					</IonSlides>
				</div>
				<IonRefresher slot="fixed" onIonRefresh={doRefresh} pullFactor={0.5} pullMin={100} pullMax={200}>
					<IonRefresherContent></IonRefresherContent>
				</IonRefresher>
				<IonGrid>
					<IonRow>
						{ filteredStories && filteredStories.map((story, index) => (
							<IonCol size="12" size-md="6" size-lg="6" key={index}>
								<IonCard href={TAB_PATH + "/companies/" + companySlug + "/stories/" + story.slug} className="ion-no-margin">
									<div className="card-image-wrapper">
										{ story.promo && story.image ? (
											<div className="card-promo">{story.promo}</div>
										) : null }
										{ story.image ? (
											<IonImg className="card-image-vertical" src={process.env.REACT_APP_URL + '/file/' + story.image} />
										) : null }
									</div>
									<IonCardHeader>
										<IonCardTitle color="primary" className="service-title">{story.title}</IonCardTitle>
									</IonCardHeader>
									<IonCardContent>
										<div className="ion-text-justify">
											{renderHTML(story.excerpt)}
										</div>
									</IonCardContent>
								</IonCard>
							</IonCol>
						))}
					</IonRow>
				</IonGrid>
    		</IonContent>
		</IonPage>
	);
};

export default Stories;
