import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonPage, IonText, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import middlewareAuth from '../../../middleware/middleware-auth';
import TAB_PATH from '../../../helpers/tabPath';
import { arrowBackOutline } from 'ionicons/icons';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import handleError from '../../../helpers/handleError';
import axiosInstance from '../../../helpers/axiosInstance';

const Order = ({title}) => {

	middlewareAuth();

	const { orderId } = useParams();
	const [order, setOrder] = useState('');
	const [showToast, setShowToast] = useState(false);
	const [toast, setToast] = useState({
		color: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
		message: process.env.REACT_APP_DEFAULT_TOAST_MESSAGE,
		duration: process.env.REACT_APP_DEFAULT_TOAST_DURATION
	});

	let getOrder = () => {
		axiosInstance()
			.get('/orders/' + orderId)
			.then((res) => {
				setOrder(res.data.data);
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	useEffect(() => {
		getOrder();
	}, []);

	return (
		<IonPage>
			<IonHeader className="ion-no-border" translucent="true">
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton color="secondary" className="toolbar-back-icon" text="" defaultHref={TAB_PATH + "/profile/orders"} icon={arrowBackOutline} />
					</IonButtons>
					<IonTitle color="secondary" className="toolbar-title-with-icon">{title}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen="true">
				<IonToast
					color={toast.color}
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={toast.message}
					duration={toast.duration}
				/>
      			<IonCard>
					<IonCardHeader>
						{ order ? (
							<>
								<IonCardTitle color="primary">Ordine n. {order.number}</IonCardTitle>
								<IonCardSubtitle color="medium">del {order.date}</IonCardSubtitle>
								{ order.amount === '0,00' ? (
									<IonCardSubtitle color="medium">Prenotato da {order.company_name}</IonCardSubtitle>
								) : (
									<IonCardSubtitle color="medium">Ordinato da {order.company_name}</IonCardSubtitle>
								) }
							</>
						) : null }
					</IonCardHeader>
					<IonCardContent>
						{ order.status ? (
							<>
								<IonCardSubtitle color="primary">Stato</IonCardSubtitle>
								{order.status}<br /><br />
							</>
						) : null }
						{ order.delivery_method ? (
							<>
								<IonCardSubtitle color="primary">Consegna</IonCardSubtitle>
								{order.delivery_method}<br /><br />
							</>
						) : null }
						{ order.delivery_datetime ? (
							<>
								<IonCardSubtitle color="primary">
									{ order.delivery_method === 'takeaway' ? (
										'Data e ora di ritiro'
									) :
										'Data e ora di consegna'
									}
								</IonCardSubtitle>
								{order.delivery_datetime}<br /><br />
							</>
						) : null }
						{ order.payment_method ? (
							<>
								<IonCardSubtitle color="primary">Pagamento</IonCardSubtitle>
								{order.payment_method}<br /><br />
							</>
						) : null }
						{ order.notes ? (
							<>
								<IonCardSubtitle color="primary">Note</IonCardSubtitle>
								{order.notes}<br /><br />
							</>
						) : null }
						{ order.invoice_address ? (
							<>
								<IonCardSubtitle color="primary">Indirizzo di fatturazione</IonCardSubtitle>
								<strong>Ragione sociale:</strong> {order.invoice_address.company}<br />
								<strong>Codice Fiscale:</strong> {order.invoice_address.tax_code}<br />
								<strong>Partita IVA:</strong> {order.invoice_address.vat_number}<br />
								<strong>PEC:</strong> {order.invoice_address.pec}<br />
								<strong>Codice destinatario:</strong> {order.invoice_address.ei_code}<br />
								<strong>Telefono:</strong> {order.invoice_address.phone_number}<br />
								<strong>Indirizzo:</strong> {order.invoice_address.address}<br />
								{order.invoice_address.zip_code} {order.invoice_address.city} ({order.invoice_address.province})<br />
								{order.invoice_address.country}<br />
								<strong>Note:</strong> {order.invoice_address.notes}<br />
								<br />
							</>
						) : null }
						{ order.delivery_address ? (
							<>
								<IonCardSubtitle color="primary">Indirizzo di spedizione</IonCardSubtitle>
								<strong>Ragione sociale:</strong> {order.delivery_address.company}<br />
								<strong>Telefono:</strong> {order.delivery_address.phone_number}<br />
								<strong>Indirizzo:</strong> {order.delivery_address.address}<br />
								{order.delivery_address.zip_code} {order.delivery_address.city} ({order.delivery_address.province})<br />
								{order.delivery_address.country}<br />
								<strong>Note:</strong> {order.delivery_address.notes}<br />
								<br />
							</>
						) : null }
						{ order ? (
							<>
								<IonCardSubtitle color="primary">Riepilogo</IonCardSubtitle>
								<div className="list-checkout-items">
									{order && order.details && order.details.map((detail, index) => (
										<div className="list-cart-item" key={index}>
											<IonText color="primary" className="checkout-price">
												{ detail.price ? detail.currency_code + ' ' + detail.price : null }
											</IonText>
											<IonText color="primary" className="checkout-quantity">
												{ parseInt(detail.quantity) }
											</IonText>
											<IonText color="primary" className="checkout-item">
												{ detail.name ? detail.name : null }
												{ detail.booking_datetime ? (
													<>
														<br />
														<IonText color="primary" className="checkout-item-booking">
															Prenotato il {detail.booking_datetime}
														</IonText>
													</>
												) : null }
											</IonText>
											<br />
										</div>
									))}
								</div>
								<IonItem className="checkout-total">
									<IonLabel color="medium">Costo di consegna</IonLabel>
									<IonInput type="text" value={order.currency_code + ' ' + order.shipping_total} readonly></IonInput>
								</IonItem>
								<IonItem className="checkout-total">
									<IonLabel color="medium">Totale</IonLabel>
									<IonInput type="text" value={order.currency_code + ' ' + order.amount} readonly></IonInput>
								</IonItem>
							</>
						) : null }
					</IonCardContent>
				</IonCard>
    		</IonContent>
		</IonPage>
	);
};

export default Order;
