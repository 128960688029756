import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonPage, IonRefresher, IonRefresherContent, IonRow, IonSearchbar, IonSlide, IonSlides, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import middlewareAuth from '../../../middleware/middleware-auth';
import TAB_PATH from '../../../helpers/tabPath';
import { arrowBackOutline, cartOutline } from 'ionicons/icons';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../helpers/axiosInstance';
import handleError from '../../../helpers/handleError';
import getQueryString from '../../../helpers/getQueryString';
import pluckUnique from '../../../helpers/pluckUnique';

const Services = ({title}) => {

  	middlewareAuth();

	let category = getQueryString(window, 'category');

	const { companySlug } = useParams();
	const slideOpts = {
		slidesPerView: 'auto',
		zoom: false,
		grabCursor: true,
	};
	const [services, setServices] = useState([])
	const [filteredServices, setFilteredServices] = useState([])
	const [filters, setFilters] = useState([])
	const [search, setSearch] = useState('')
	const [categoryFilter, setCategoryFilter] = useState('')
	const [addedToCart, setAddedToCart] = useState(localStorage['cart_items_count_' + companySlug] > 0 ? true : false)
	const [showToast, setShowToast] = useState(false)
	const [toast, setToast] = useState({
		color: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
		message: process.env.REACT_APP_DEFAULT_TOAST_MESSAGE,
		duration: process.env.REACT_APP_DEFAULT_TOAST_DURATION
	})

	let getServices = () => {
		axiosInstance()
			.get('/services/' + companySlug)
			.then((res) => {
				setServices(res.data.data)
				setFilteredServices(res.data.data);
				setFilters(pluckUnique(res.data.data, 'category').sort((a, b) => a.localeCompare(b)))
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	let doRefresh = (event) => {
		getServices();
		setSearch('');
		setCategoryFilter('');

		setTimeout(() => {
			event.detail.complete();
		}, 2000);
	}

	useEffect(() => {
		getServices();
	}, []);

	useEffect(() => {
		if ( services ) {
			let temp = services.filter(el => {
				if ( categoryFilter && search ) {
					return el.category && el.category.includes(categoryFilter) && el.title.toLowerCase().includes(search.toLowerCase());
				}

				if ( categoryFilter ) {
					return el.category && el.category.includes(categoryFilter);
				}

				if ( search ) {
					return el.title.toLowerCase().includes(search.toLowerCase());
				}

				return el;
			})
			setFilteredServices([...temp]);
		}
	}, [search, categoryFilter]);

  	return (
		<IonPage>
			<IonHeader className="ion-no-border" translucent="true">
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton color="secondary" className="toolbar-back-icon" text="" defaultHref={TAB_PATH + "/companies/" + companySlug} icon={arrowBackOutline} />
					</IonButtons>
					<IonTitle color="secondary" className="toolbar-title-with-icon">{title}</IonTitle>
					{ services && services[0] ? (
						<IonButton slot="end" size="small" color={addedToCart === false ? 'primary' : 'success'} className="btn-go-to-cart" href={TAB_PATH + "/companies/" + companySlug + "/cart"}>
							{localStorage['cart_items_count_' + companySlug] ? localStorage['cart_items_count_' + companySlug] : null}
							<IonIcon icon={cartOutline} className="btn-icon-cart"></IonIcon>
							{localStorage['cart_amount_' + companySlug] && localStorage['cart_amount_' + companySlug] !== '0,00' ? services[0].currency_code + ' ' + localStorage['cart_amount_' + companySlug] : null}
						</IonButton>
					) : null }
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen="true">
				<IonToast
					color={toast.color}
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={toast.message}
					duration={toast.duration}
				/>
				{ services && services[0] ? (
					<IonCardSubtitle className="company-name-title" color="primary">
						I servizi di {services[0].company_name}
					</IonCardSubtitle>
				) : null }
				<IonSearchbar placeholder="Cerca" animated value={search} onIonChange={e => setSearch(e.detail.value)}></IonSearchbar>
				<div className="filters-container">
					<IonSlides options={slideOpts}>
						<IonSlide className="filter-element" onClick={e => setCategoryFilter('')}>
							<IonButton size="small" className={ categoryFilter === '' ? 'btn-secondary' : 'btn-primary' }>
								Tutti
							</IonButton>
						</IonSlide>
						{ filters && filters.map((category, index) => (
							<IonSlide className="filter-element" key={index} onClick={e => setCategoryFilter(category)}>
								<IonButton size="small" className={ category === categoryFilter ? 'btn-secondary' : 'btn-primary' }>
									{category}
								</IonButton>
							</IonSlide>
						))}
					</IonSlides>
				</div>
				<IonRefresher slot="fixed" onIonRefresh={doRefresh} pullFactor={0.5} pullMin={100} pullMax={200}>
					<IonRefresherContent></IonRefresherContent>
				</IonRefresher>
				<IonGrid>
					<IonRow>
						{ filteredServices && filteredServices.map((service, index) => (
							<IonCol size="12" size-md="6" size-lg="6" key={index}>
								<div>
									{ filteredServices[index - 1] !== undefined && filteredServices[index - 1].pos != service.pos || (index === 0 && pluckUnique(filteredServices, 'pos').length > 1 ) ? (
										<IonCardSubtitle className="service-pos-title" color="primary">
											{service.pos}
										</IonCardSubtitle>
									) : null }
									<IonCard href={TAB_PATH + "/companies/" + companySlug + "/services/" + service.slug} className="ion-no-margin">
										{ service.slide_image_1 ? (
											<IonImg className="services-card-image" src={process.env.REACT_APP_URL + '/file/' + service.slide_image_1} />
										) : null }
										<IonCardHeader>
											<IonCardTitle color="primary" className="service-title">{service.title}</IonCardTitle>
											<IonCardSubtitle color="medium" className="service-subtitle">Durata: {service.duration}</IonCardSubtitle>
											{ service.price === "0,00" ? (
												<IonCardSubtitle color="primary" className="service-price">PRENOTAZIONE GRATUITA</IonCardSubtitle>
											) : (
												<IonCardSubtitle color="primary" className="service-price">{service.sale_price !== "0,00" ? service.currency_code + ' ' + service.sale_price : service.currency_code + ' ' + service.price}</IonCardSubtitle>
											) }
										</IonCardHeader>
									</IonCard>
								</div>
							</IonCol>
						))}
					</IonRow>
				</IonGrid>
    		</IonContent>
		</IonPage>
	);
};

export default Services;
