import { IonCard, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { Link } from 'react-router-dom';
import RegisterContainer from '../../../../containers/public/auth/register';
import middlewarePublic from '../../../../middleware/middleware-public';

const Register = ({title}) => {

	middlewarePublic();

	return (
		<IonPage>
			<IonHeader className="ion-no-border" translucent="true">
				<IonToolbar>
					<IonTitle size="large" color="secondary"></IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen="true">
				<div className="welcome-title">
					Registrati a SharingApp
				</div>
				<div className="welcome-subtitle">
					Inserisci i tuoi dati e seleziona la tua attività preferita per accedere a tutti i servizi
				</div>
				<IonCard>
					<RegisterContainer />
					<div className="welcome-link">
						<Link to="/">
							Hai già un account?<br /> Effetta il login!
						</Link>
					</div>
				</IonCard>
			</IonContent>
		</IonPage>
	);
};

export default Register;
