import {useState, useContext, useEffect} from 'react';
import {login} from '../../../../context/actions/public/auth/login';
import {GlobalContext} from '../../../../context/Provider';
import checkAllFieldAreFilled from '../../../../helpers/form';
import {useHistory, useParams, useLocation} from 'react-router-dom';
import TAB_PATH from '../../../../helpers/tabPath';

export default () => {

	const history = useHistory();
	const { redirect } = useParams();
	const location = useLocation();
	const query = new URLSearchParams(location.search);

	const [toastError, setToastError] = useState('');
	const [submitted, setSubmitted] = useState(false);

	const keysToFill = [
		'email',
		'password',
	];

	const [form, setForm] = useState({
		email: '',
		password: '',
	});

	const {
		authDispatch,
		authState: {
			auth: {
				loading,
				loginErrors,
				data
			}
		}
	} = useContext(GlobalContext);

	useEffect(() => {
		if ( loginErrors && submitted ) {
			setToastError(loginErrors[Object.keys(loginErrors)[0]][0]);
		}
	}, [loginErrors]);

	useEffect(() => {
		if ( data ) {
			localStorage.token = data.data.access_token;
			if ( query.get('redirect') ) {
				history.push(atob(query.get('redirect')));
			} else {
				history.push(TAB_PATH + "/companies");
			}
		}
	}, [data]);

	const onChange = (e) => {
		setForm({...form, [e.target.name]: e.target.value});
	};

	const onSubmit = () => {
		setSubmitted(true);
		login(form)(authDispatch);
	};

	const untilKeysAreFilled = checkAllFieldAreFilled(form, keysToFill);


	return {form, onChange, onSubmit, untilKeysAreFilled, loading, toastError, setToastError};
};
