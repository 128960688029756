import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonImg, IonPage, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import middlewareAuth from '../../../middleware/middleware-auth';
import TAB_PATH from '../../../helpers/tabPath';
import { arrowBackOutline, arrowForwardOutline, atOutline, attachOutline, bagOutline, callOutline, chatbubblesOutline, musicalNotesOutline } from 'ionicons/icons';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../helpers/axiosInstance';
import handleError from '../../../helpers/handleError';
import renderHTML from '../../../helpers/renderHTML';

const Offer = ({title}) => {

	middlewareAuth();

	const { offerSlug } = useParams();
	const [offer, setOffer] = useState([]);
	const [showToast, setShowToast] = useState(false);
	const [toast, setToast] = useState({
		color: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
		message: process.env.REACT_APP_DEFAULT_TOAST_MESSAGE,
		duration: process.env.REACT_APP_DEFAULT_TOAST_DURATION
	});

	let getOffer = () => {
		axiosInstance()
			.get('/offers/' + offerSlug)
			.then((res) => {
				setOffer(res.data.data)
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	useEffect(() => {
		getOffer();
	}, []);

	return (
		<IonPage>
			<IonHeader className="ion-no-border" translucent="true">
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton color="secondary" className="toolbar-back-icon" text="" defaultHref={TAB_PATH + "/offers"} icon={arrowBackOutline} />
					</IonButtons>
					<IonTitle color="secondary" className="toolbar-title-with-icon">{title}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen="true">
				<IonToast
					color={toast.color}
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={toast.message}
					duration={toast.duration}
				/>
      			<IonCard>
				  	{ offer.image ? (
						<IonImg className="card-image" src={process.env.REACT_APP_URL + '/file/' + offer.image} />
					) : null }
					<IonCardHeader>
						<IonCardTitle color="primary">{offer.title}</IonCardTitle>
						{ offer.company ? (
							<IonCardSubtitle color="medium">{offer.company.name}</IonCardSubtitle>
						) : null }
						<IonCardSubtitle color="medium">{offer.category}</IonCardSubtitle>
					</IonCardHeader>
					<IonCardContent>
						<div className="ion-text-justify">
							{renderHTML(offer.text)}
						</div>
						<br />
						{ offer.audio ? (
							<IonButton size="small" className="btn-secondary" href={process.env.REACT_APP_URL + '/file/' + offer.audio} target="_blank">
								<IonIcon icon={musicalNotesOutline} className="btn-icon-blue"></IonIcon>
								&nbsp;
								Ascolta l'audio
							</IonButton>
						) : null }
						{ offer.file ? (
							<IonButton size="small" className="btn-secondary" href={process.env.REACT_APP_URL + '/file/' + offer.file} target="_blank">
								<IonIcon icon={attachOutline} className="btn-icon-blue"></IonIcon>
								&nbsp;
								Apri l'allegato
							</IonButton>
						) : null }
						{ offer.audio || offer.file ? (
							<>
								<br /><br />
							</>
						) : null }
						{ offer.product || offer.service ? (
							<IonCardSubtitle color="medium" className="subtitle-before-btn">Acquista</IonCardSubtitle>
						) : null }
						{ offer.product ? (
							<>
								<IonButton color="primary" className="btn-primary" href={TAB_PATH + "/companies/" + offer.product.company_slug + "/products/" + offer.product.slug}>
									Scopri il prodotto
									&nbsp;
									<IonIcon icon={arrowForwardOutline} className="btn-icon-white"></IonIcon>
								</IonButton>
								<br />
							</>
						) : null }
						{ offer.service ? (
							<>
								<IonButton color="primary" className="btn-primary" href={TAB_PATH + "/companies/" + offer.service.company_slug + "/services/" + offer.service.slug}>
									Scopri il servizio
									&nbsp;
									<IonIcon icon={arrowForwardOutline} className="btn-icon-white"></IonIcon>
								</IonButton>
								<br />
							</>
						) : null }
						{ offer.product || offer.service ? (
							<>
								<br />
							</>
						) : null }
						{ offer.company ? (
							<IonCardSubtitle color="medium" className="subtitle-before-btn">Scopri di più</IonCardSubtitle>
						) : null }
						{ offer.company ? (
							<IonButton size="small" className="btn-secondary" href={TAB_PATH + "/companies/" + offer.company.slug}>
								<IonIcon icon={bagOutline} className="btn-icon-blue"></IonIcon>
								&nbsp;
								Scopri {offer.company.name}
							</IonButton>
						) : null }
						{ offer.company && offer.company.whatsapp_phone ? (
							<>
								<IonButton size="small" className="btn-secondary" href={'https://wa.me/' + offer.company.whatsapp_phone.replace(/\s/g, '')}>
									<IonIcon icon={chatbubblesOutline} className="btn-icon-blue"></IonIcon>
									&nbsp;
									WhatsApp
								</IonButton>
								<br />
							</>
						) : null }
						{ offer.company && offer.company.phone_number ? (
							<>
								<IonButton size="small" className="btn-secondary" href={'tel:' + offer.company.phone_number}>
									<IonIcon icon={callOutline} className="btn-icon-blue"></IonIcon>
									&nbsp;
									Chiama
								</IonButton>
								<br />
							</>
						) : null }
						{ offer.company && offer.company.email ? (
							<>
								<IonButton size="small" className="btn-secondary" href={'mailto:' + offer.company.email}>
									<IonIcon icon={atOutline} className="btn-icon-blue"></IonIcon>
									&nbsp;
									Scrivi
								</IonButton>
								<br />
							</>
						) : null }
					</IonCardContent>
				</IonCard>
    		</IonContent>
		</IonPage>
	);
};

export default Offer;
