import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonImg, IonPage, IonSelect, IonSelectOption, IonSlide, IonSlides, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import middlewareAuth from '../../../middleware/middleware-auth';
import TAB_PATH from '../../../helpers/tabPath';
import { arrowBackOutline, addCircleOutline, cartOutline, arrowForwardOutline, atOutline, attachOutline, bagOutline, callOutline, chatbubblesOutline, musicalNotesOutline } from 'ionicons/icons';
import { useHistory, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../helpers/axiosInstance';
import handleError from '../../../helpers/handleError';
import renderHTML from '../../../helpers/renderHTML';

const Story = ({title}) => {

	middlewareAuth();

	const slideOpts = {
		autoplay: true,
		loop: true,
		slidesPerView: 1,
		speed: 1000,
		delay: 5000,
	};

	const history = useHistory();
	const { companySlug, storySlug } = useParams()
	const [story, setStory] = useState([]);
	const [addedToCart, setAddedToCart] = useState(localStorage['cart_items_count_' + companySlug] > 0 ? true : false)
	const [showToast, setShowToast] = useState(false);
	const [toast, setToast] = useState({
		color: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
		message: process.env.REACT_APP_DEFAULT_TOAST_MESSAGE,
		duration: process.env.REACT_APP_DEFAULT_TOAST_DURATION
	});

	let getStory = () => {
		axiosInstance()
			.get('/stories/' + companySlug + '/' + storySlug)
			.then((res) => {
				setStory(res.data.data)
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	useEffect(() => {
		getStory();
	}, []);

	return (
		<IonPage>
			<IonHeader className="ion-no-border" translucent="true">
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton color="secondary" className="toolbar-back-icon" text="" defaultHref={TAB_PATH + "/companies/" + companySlug + "/stories"} icon={arrowBackOutline} />
					</IonButtons>
					<IonTitle color="secondary" className="toolbar-title-with-icon">{title}</IonTitle>
					{ story ? (
						<IonButton slot="end" size="small" color={addedToCart === false ? 'primary' : 'success'} className="btn-go-to-cart" href={TAB_PATH + "/companies/" + companySlug + "/cart"}>
							{localStorage['cart_items_count_' + companySlug] ? localStorage['cart_items_count_' + companySlug] : null}
							<IonIcon icon={cartOutline} className="btn-icon-cart"></IonIcon>
							{localStorage['cart_amount_' + companySlug] && localStorage['cart_amount_' + companySlug] !== '0,00' ? story.currency_code + ' ' + localStorage['cart_amount_' + companySlug] : null}
						</IonButton>
					) : null }
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen="true">
				<IonToast
					color={toast.color}
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={toast.message}
					duration={toast.duration}
				/>
      			<IonCard>
				  	{ story.image ? (
						<IonImg className="card-image-vertical" src={process.env.REACT_APP_URL + '/file/' + story.image} />
					) : null }
					<IonCardHeader>
						<IonCardTitle color="primary">{story.title}</IonCardTitle>
						{ story.company ? (
							<IonCardSubtitle color="medium">{story.company.name}</IonCardSubtitle>
						) : null }
						<IonCardSubtitle color="medium">{story.category}</IonCardSubtitle>
					</IonCardHeader>
					<IonCardContent>
						<div className="ion-text-justify">
							{renderHTML(story.text)}
						</div>
						<br />
						{ story.audio ? (
							<IonButton size="small" className="btn-secondary" href={process.env.REACT_APP_URL + '/file/' + story.audio} target="_blank">
								<IonIcon icon={musicalNotesOutline} className="btn-icon-blue"></IonIcon>
								&nbsp;
								Ascolta l'audio
							</IonButton>
						) : null }
						{ story.file ? (
							<IonButton size="small" className="btn-secondary" href={process.env.REACT_APP_URL + '/file/' + story.file} target="_blank">
								<IonIcon icon={attachOutline} className="btn-icon-blue"></IonIcon>
								&nbsp;
								Apri l'allegato
							</IonButton>
						) : null }
						{ story.audio || story.file ? (
							<>
								<br /><br />
							</>
						) : null }
						{ story.product || story.service ? (
							<IonCardSubtitle color="medium" className="subtitle-before-btn">Acquista</IonCardSubtitle>
						) : null }
						{ story.product ? (
							<>
								<IonButton color="primary" className="btn-primary" href={TAB_PATH + "/companies/" + story.product.company_slug + "/products/" + story.product.slug}>
									Scopri il prodotto
									&nbsp;
									<IonIcon icon={arrowForwardOutline} className="btn-icon-white"></IonIcon>
								</IonButton>
								<br />
							</>
						) : null }
						{ story.service ? (
							<>
								<IonButton color="primary" className="btn-primary" href={TAB_PATH + "/companies/" + story.service.company_slug + "/services/" + story.service.slug}>
									Scopri il servizio
									&nbsp;
									<IonIcon icon={arrowForwardOutline} className="btn-icon-white"></IonIcon>
								</IonButton>
								<br />
							</>
						) : null }
						{ story.product || story.service ? (
							<>
								<br />
							</>
						) : null }
						<IonCardSubtitle color="medium" className="subtitle-before-btn">Scopri di più</IonCardSubtitle>
						{ story.company_whatsapp_phone ? (
							<>
								<IonButton size="small" className="btn-secondary" href={'https://wa.me/' + story.company_whatsapp_phone.replace(/\s/g, '')}>
									<IonIcon icon={chatbubblesOutline} className="btn-icon-blue"></IonIcon>
									&nbsp;
									WhatsApp
								</IonButton>
								<br />
							</>
						) : null }
						{ story.company_phone_number ? (
							<>
								<IonButton size="small" className="btn-secondary" href={'tel:' + story.company_phone_number}>
									<IonIcon icon={callOutline} className="btn-icon-blue"></IonIcon>
									&nbsp;
									Chiama
								</IonButton>
								<br />
							</>
						) : null }
						{ story.company_email ? (
							<>
								<IonButton size="small" className="btn-secondary" href={'mailto:' + story.company_email}>
									<IonIcon icon={atOutline} className="btn-icon-blue"></IonIcon>
									&nbsp;
									Scrivi
								</IonButton>
								<br />
							</>
						) : null }
					</IonCardContent>
				</IonCard>
    		</IonContent>
		</IonPage>
	);
};

export default Story;
