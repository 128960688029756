import { IonButton, IonCard, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonLabel, IonPage, IonRow, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../../../../helpers/axiosInstance';
import handleError from '../../../../helpers/handleError';
import middlewarePublic from '../../../../middleware/middleware-public';
import { useHistory } from 'react-router';

const ResetPassword = ({title}) => {

	middlewarePublic();

	const history = useHistory();
    const [email, setEmail] = useState('');
    const [showToast, setShowToast] = useState(false);
	const [toast, setToast] = useState({
		color: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
		message: process.env.REACT_APP_DEFAULT_TOAST_MESSAGE,
		duration: process.env.REACT_APP_DEFAULT_TOAST_DURATION
	});

    let onSubmit = () => {
		axiosInstance()
			.post('/reset-password',
				{
					email: email,
				}
			).then((res) => {
				setToast({...toast,
					['color']: 'success',
					['message']: 'Ti abbiamo inviato un\'email per inserire la nuova password.',
				})
				setShowToast(true);

				setTimeout(() => {
					history.push("/");
				}, 4000);
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	return (
		<IonPage>
			<IonHeader className="ion-no-border" translucent="true">
				<IonToolbar>
					<IonTitle size="large" color="secondary"></IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen="true">
                <IonToast
					color={toast.color}
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={toast.message}
					duration={toast.duration}
				/>
				<div className="welcome-title">
					Richiedi una nuova password
				</div>
				<div className="welcome-subtitle">
					Inserisci l'indirizzo email con cui hai effettuato la registrazione, riceverai un'email e potrai inserire una nuova password
				</div>
				<IonCard>
					<IonGrid className="register-grid">
						<IonRow>
							<IonCol>
								<IonItem className="ion-no-padding">
									<IonLabel position="floating">Email</IonLabel>
									<IonInput
										onIonChange={(e) => {
											setEmail(e.target.value);
										}}
										type="email"
										name="email"
									></IonInput>
								</IonItem>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol>
								<IonButton expand="full" className="ion-no-margin" onClick={onSubmit}>
									Conferma
								</IonButton>
							</IonCol>
						</IonRow>
						<div className="welcome-link">
							<Link to="/">
								Torna indietro
							</Link>
						</div>
					</IonGrid>
				</IonCard>
			</IonContent>
		</IonPage>
	);
};

export default ResetPassword;
