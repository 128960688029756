import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonContent, IonHeader, IonIcon, IonPage, IonText, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import middlewareAuth from '../../../middleware/middleware-auth';
import TAB_PATH from '../../../helpers/tabPath';
import { arrowBackOutline, arrowForwardCircleOutline } from 'ionicons/icons';
import handleError from '../../../helpers/handleError';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../helpers/axiosInstance';
import { useHistory } from 'react-router';

const Carts = ({title}) => {

	middlewareAuth();

	const history = useHistory();

	const [carts, setCarts] = useState('')
	const [showToast, setShowToast] = useState(false)
	const [toast, setToast] = useState({
		color: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
		message: process.env.REACT_APP_DEFAULT_TOAST_MESSAGE,
		duration: process.env.REACT_APP_DEFAULT_TOAST_DURATION
	})

	let getCarts = () => {
		axiosInstance()
			.get('/cart')
			.then((res) => {
				if ( res.data.data.length === 0 ) {
					setCarts([])
				} else {
					setCarts(res.data.data)
				}
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	const goBack = () => {
		history.goBack()
	}

	useEffect(() => {
		getCarts();
	}, []);

	return (
		<IonPage>
			<IonHeader className="ion-no-border" translucent="true">
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton color="secondary" className="toolbar-back-icon" text="" defaultHref="" onClick={goBack} icon={arrowBackOutline} />
					</IonButtons>
					<IonTitle color="secondary" className="toolbar-title-with-icon">{title}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen="true">
				<IonToast
					color={toast.color}
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={toast.message}
					duration={toast.duration}
				/>
				{ carts && carts.length > 0 ? (
					<IonCard>
						<IonCardHeader>
							<IonCardSubtitle color="medium">Ecco i tuoi carrelli attivi, completali!</IonCardSubtitle>
						</IonCardHeader>
						<IonCardContent>
							<div className="list-other-carts">
								{ carts.map((item, index) => (
									<div className="list-other-cart" key={index}>
										<IonText color="primary" className="cart-title">{item.company_name}</IonText>
										<IonButton className="btn-go-to-other-cart" href={TAB_PATH + "/companies/" + item.company_slug + "/cart"}>
											<IonIcon icon={arrowForwardCircleOutline}></IonIcon>
										</IonButton>
									</div>
								))}
							</div>
						</IonCardContent>
					</IonCard>
				) : null }
				{ carts && carts.length === 0 ? (
					<IonCard>
						<IonCardHeader>
							<IonCardSubtitle color="medium">Al momento non sono presenti carrelli.</IonCardSubtitle>
							<IonCardSubtitle color="medium">Torna indietro e seleziona un'attività per crearne uno!</IonCardSubtitle>
						</IonCardHeader>
					</IonCard>
				) : null }
			</IonContent>
		</IonPage>
	);
};

export default Carts;
