import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonIcon, IonImg, IonPage, IonSelect, IonSelectOption, IonSlide, IonSlides, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import middlewareAuth from '../../../middleware/middleware-auth';
import TAB_PATH from '../../../helpers/tabPath';
import { arrowBackOutline, addCircleOutline, cartOutline } from 'ionicons/icons';
import { useHistory, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../helpers/axiosInstance';
import handleError from '../../../helpers/handleError';
import renderHTML from '../../../helpers/renderHTML';

const Service = ({title}) => {

	middlewareAuth();

	const slideOpts = {
		autoplay: true,
		loop: true,
		slidesPerView: 1,
		speed: 1000,
		delay: 5000,
	};

	const history = useHistory();
	const { companySlug, serviceSlug } = useParams()
	const [service, setService] = useState([])
	const [dates, setDates] = useState([])
	const [slots, setSlots] = useState([])
	const [selectedSlots, setSelectedSlots] = useState([])
	const [date, setDate] = useState('')
	const [selectedSlot, setSelectedSlot] = useState('')
	const [amount, setAmount] = useState(localStorage['cart_amount_' + companySlug] || '')
	const [itemsCount, setItemsCount] = useState(localStorage['cart_items_count_' + companySlug] || '')
	const [addedToCart, setAddedToCart] = useState(localStorage['cart_items_count_' + companySlug] > 0 ? true : false)
	const [disableAddButton, setDisableAddButton] = useState(true)
	const [showToast, setShowToast] = useState(false)
	const [toast, setToast] = useState({
		color: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
		message: process.env.REACT_APP_DEFAULT_TOAST_MESSAGE,
		duration: process.env.REACT_APP_DEFAULT_TOAST_DURATION
	})

	let getService = () => {
		axiosInstance()
			.get('/services/' + companySlug + '/' + serviceSlug)
			.then((res) => {
				setService(res.data.data)
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	let getSlots = () => {
		axiosInstance()
			.get('/availability/service/' + serviceSlug)
			.then((res) => {
				setDates(Object.keys(res.data))
				setSlots(res.data)
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	let onDatesChange = (e) => {
		setSelectedSlots(slots[e.detail.value])
		setDate(e.detail.value)
	}

	let onSlotChange = (e) => {
		setSelectedSlot(e.detail.value)
	}

	const addItem = (e) => {
		e.preventDefault();

		if ( date.length === 0 || selectedSlot.length === 0 ) {
			return;
		}

		axiosInstance()
			.post('/cart/add', {
				items: [
					{
						company_id: service.company_id,
						type: 'Service',
						id: service.id,
						title: service.title,
						price: service.sale_price !== "0,00" ? service.sale_price : service.price,
						booking_datetime: date.length > 0 && selectedSlot.length > 0 ? date + ' ' + selectedSlot + ':00' : ''
					}
				]
			}).then((res) => {

				let amount = res.data.data.amount;

				setAmount(amount)
				setItemsCount(res.data.data.items_count)
				setToast({...toast,
					['color']: 'success',
					['message']: 'Il servizio è stato aggiunto al carrello.',
				})
				setShowToast(true);

				setAddedToCart(true);

				if ( amount == '0,00' ) {
					setTimeout(() => {
						history.push(TAB_PATH + '/companies/' + companySlug + '/cart');
					}, 2000);
				}

			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	};

	useEffect(() => {
		getService();
		getSlots();
	}, []);

	useEffect(() => {
		localStorage['cart_amount_' + companySlug] = amount;
	}, [amount]);

	useEffect(() => {
		localStorage['cart_items_count_' + companySlug] = itemsCount;
	}, [itemsCount]);

	useEffect(() => {
		if ( date ) {
			setDisableAddButton(false);
		}
	}, [date]);

	return (
		<IonPage>
			<IonHeader className="ion-no-border" translucent="true">
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton color="secondary" className="toolbar-back-icon" text="" defaultHref={TAB_PATH + "/companies/" + companySlug + "/services"} icon={arrowBackOutline} />
					</IonButtons>
					<IonTitle color="secondary" className="toolbar-title-with-icon">{title}</IonTitle>
					{service ? (
						<IonButton slot="end" size="small" color={addedToCart === false ? 'primary' : 'success'} className="btn-go-to-cart" href={TAB_PATH + "/companies/" + companySlug + "/cart"}>
							{localStorage['cart_items_count_' + companySlug] ? localStorage['cart_items_count_' + companySlug] : null}
							<IonIcon icon={cartOutline} className="btn-icon-cart"></IonIcon>
							{localStorage['cart_amount_' + companySlug] && localStorage['cart_amount_' + companySlug] !== '0,00' ? service.currency_code + ' ' + localStorage['cart_amount_' + companySlug] : null}
						</IonButton>
					) : null }
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen="true">
				<IonToast
					color={toast.color}
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={toast.message}
					duration={toast.duration}
				/>
				<IonCard>
					{/* SLIDES */}
					{ service.slide_image_1 && !service.slide_image_2 && !service.slide_image_3 ? (
						<IonImg className="service-card-image" src={process.env.REACT_APP_URL + '/file/' + service.slide_image_1} />
					) : (
						<IonSlides options={slideOpts} pager={true}>
							{ service.slide_image_1 ? (
								<IonSlide>
									<IonImg className="service-card-image" src={process.env.REACT_APP_URL + '/file/' + service.slide_image_1} />
								</IonSlide>
							) : null }
							{ service.slide_image_2 ? (
								<IonSlide>
									<IonImg className="service-card-image" src={process.env.REACT_APP_URL + '/file/' + service.slide_image_2} />
								</IonSlide>
							) : null }
							{ service.slide_image_3 ? (
								<IonSlide>
									<IonImg className="service-card-image" src={process.env.REACT_APP_URL + '/file/' + service.slide_image_3} />
								</IonSlide>
							) : null }
						</IonSlides>
					) }
					<IonCardHeader>
						<IonCardTitle color="primary">{service.title}</IonCardTitle>
						<IonCardSubtitle color="medium">{service.company_name} - {service.pos}</IonCardSubtitle>
						<IonCardSubtitle color="medium">{service.category}</IonCardSubtitle>
					</IonCardHeader>
					<IonCardContent>
						<div className="ion-text-justify">
							{renderHTML(service.text)}
						</div>
						<br />
						{ service.duration !== "1 secondo" ? (
							<>
								<IonCardSubtitle color="medium">Durata: {service.duration}</IonCardSubtitle>
								<br />
							</>
						) : null }
						{ dates && dates.length > 0 ? (
							<>
								<IonCardSubtitle color="primary">Quando desideri prenotare</IonCardSubtitle>
								<IonSelect
									interfaceOptions={{
										header: 'Scegli una data',
										subHeader: '',
										cssClass: 'custom-action-sheet'
									}}
									interface="action-sheet"
									cancelText="Annulla"
									placeholder="Scegli una data"
									onDatesChange
									onIonChange={onDatesChange}
								>
									{dates.map((date, index) => (
										<IonSelectOption value={date} key={index}>
											{ date }
										</IonSelectOption>
									))}
								</IonSelect>
							</>
						) : null }
						{ selectedSlots && selectedSlots.length > 0 ? (
							<IonSelect
								interfaceOptions={{
									header: 'Scegli un orario',
									subHeader: '',
									cssClass: 'custom-action-sheet'
								}}
								interface="action-sheet"
								cancelText="Annulla"
								placeholder="Scegli un orario"
								onIonChange={onSlotChange}
							>
								{selectedSlots.map((slot, index) => (
									<IonSelectOption value={slot} key={index}>
										{ slot }
									</IonSelectOption>
								))}
							</IonSelect>
						) : null }
						<br />
						<IonCardSubtitle color="medium" className="subtitle-before-btn">Aggiungi al carrello</IonCardSubtitle>
						<IonButton className="btn-add-to-cart-single" onClick={addItem} disabled={disableAddButton}>
							<IonIcon icon={addCircleOutline}></IonIcon>
						</IonButton>
						{ service.price === "0,00" ? (
							<IonCardSubtitle color="primary" className="service-price">PRENOTAZIONE GRATUITA</IonCardSubtitle>
						) : (
							<IonCardSubtitle color="primary" className="service-price">{service.sale_price !== "0,00" ? service.currency_code + ' ' + service.sale_price : service.currency_code + ' ' + service.price}</IonCardSubtitle>
						) }
					</IonCardContent>
				</IonCard>
    		</IonContent>
		</IonPage>
	);
};

export default Service;
