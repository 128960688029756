import axiosInstance from '../../../../helpers/axiosInstance';
import {LOGIN_LOADING, LOGIN_SUCCESS, LOGIN_ERROR} from '../../../../constants/actionTypes';
import { isPlatform } from '@ionic/react';
import { Capacitor } from '@capacitor/core';

export const login = (form) => (dispatch) => {

	dispatch({
		type: LOGIN_LOADING,
	})

	const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
	let systemOP = '';

	if ( isPlatform('ios') ) {
		systemOP = 'ios';
	} else if ( isPlatform('android') ) {
		systemOP = 'android';
	}

	axiosInstance()
		.post('/oauth/token', {
			username: form.email,
			password: form.password,
			token: (isPlatform('ios') || isPlatform('android')) && isPushNotificationsAvailable && localStorage.tokenPushPlugin ? localStorage.tokenPushPlugin : null,
            system_op: systemOP
		}).then((res) => {
			dispatch({
				type: LOGIN_SUCCESS,
				payload: res
			})
		})
		.catch((err) => {
			dispatch({
				type: LOGIN_ERROR,
				payload: err
			})
		});

};
