import {
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs,
} from '@ionic/react';
import React from 'react';
import RenderRoute from './components/routes/RenderRoute';
import middlewareAuth from './middleware/middleware-auth';
import routes from './routes';

const AppTabs = () => {

    middlewareAuth();

    return (
        <IonTabs>
            <IonRouterOutlet>
                { routes.app.tabs.map((route, index) => (
                    <RenderRoute {...route} key={index} />
                ))}
                { routes.app.companies.map((route, index) => (
                    <RenderRoute {...route} key={index} />
                ))}
                { routes.app.carts.map((route, index) => (
                    <RenderRoute {...route} key={index} />
                ))}
                { routes.app.offers.map((route, index) => (
                    <RenderRoute {...route} key={index} />
                ))}
                { routes.app.profile.map((route, index) => (
                    <RenderRoute {...route} key={index} />
                ))}
            </IonRouterOutlet>
            <IonTabBar slot="bottom" translucent="true">
                { routes.app.tabs.map((route, index) => (
                    <IonTabButton tab={route.tabName} href={route.path} key={index}>
                        <IonIcon icon={route.tabIcon} />
                        <IonLabel>{route.tabName}</IonLabel>
                    </IonTabButton>
                ))}
            </IonTabBar>
        </IonTabs>
    );
};

export default AppTabs;
