import {useState, useContext, useEffect} from 'react';
import {register} from '../../../../context/actions/public/auth/register';
import {GlobalContext} from '../../../../context/Provider';
import checkAllFieldAreFilled from '../../../../helpers/form';
import {useHistory} from 'react-router-dom';
import TAB_PATH from '../../../../helpers/tabPath';

export default () => {

	const history = useHistory();

	const [toastSuccess, setToastSuccess] = useState('');
	const [toastError, setToastError] = useState('');
	const [submitted, setSubmitted] = useState(false);

	const keysToFill = [
		'first_name',
		'last_name',
		'email',
		'phone',
		'company_slug',
		'password',
		'password_confirmation',
		'terms_and_conditions'
	];

	const [form, setForm] = useState({
		first_name: '',
		last_name: '',
		email: '',
		phone: '',
		company_slug: '',
		password: '',
		password_confirmation: '',
		terms_and_conditions: false
	});

	const {
		authDispatch,
		authState: {
			auth: {
				loading,
				registerErrors,
				data
			}
		}
	} = useContext(GlobalContext);

	useEffect(() => {
		if ( registerErrors && submitted ) {
			let i = 0;
			Object.values(registerErrors.errors).map((value) => {
				if ( i > 0 ) {
					return;
				}

				setToastError(value[0]);
				i++;
			})
		}
	}, [registerErrors]);

	useEffect(() => {
		if ( data ) {
			setToastSuccess("Grazie! Ti abbiamo inviato un'email per verificare il tuo indirizzo.");
			setTimeout(() => {
				history.push(TAB_PATH + "/companies");
			}, 4000);
		}
	}, [data]);

	const onChange = (e) => {
		setForm({...form, [e.target.name]: e.target.value});
	};

	const onSubmit = () => {
		setSubmitted(true);
		register(form)(authDispatch);
	};

	const untilKeysAreFilled = checkAllFieldAreFilled(form, keysToFill);


	return {form, onChange, onSubmit, untilKeysAreFilled, loading, toastError, setToastError, toastSuccess, setToastSuccess};
};
