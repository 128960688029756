const handleError = (err, toast, setToast, setShowToast) => {
    let errors = err.errors;
    let i = 0;
    Object.values(errors).map((value) => {
        if ( i > 0 ) {
            return;
        }

        setToast({...toast,
            ['color']: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
            ['message']: value[0],
            ['duration']: process.env.REACT_APP_DEFAULT_TOAST_DURATION,
        })
        setShowToast(true);
        i++;
    })
}

export default handleError;
