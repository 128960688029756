import axiosInstance from '../../../../helpers/axiosInstance';
import {REGISTER_LOADING, REGISTER_SUCCESS, REGISTER_ERROR} from '../../../../constants/actionTypes';
import { isPlatform } from '@ionic/react';
import { Capacitor } from '@capacitor/core';

export const register = (form) => (dispatch) => {

	dispatch({
		type: REGISTER_LOADING,
	})

	const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
	let systemOP = '';

	if ( isPlatform('ios') ) {
		systemOP = 'ios';
	} else if ( isPlatform('android') ) {
		systemOP = 'android';
	}

	axiosInstance()
		.post('/register', form)
			.then((res) => {
				axiosInstance()
					.post('/oauth/token', {
						username: form.email,
						password: form.password,
						token: (isPlatform('ios') || isPlatform('android')) && isPushNotificationsAvailable && localStorage.tokenPushPlugin ? localStorage.tokenPushPlugin : null,
						system_op: systemOP
					}).then((res) => {
						localStorage.token = res.data.access_token;
						dispatch({
							type: REGISTER_SUCCESS,
							payload: res
						})
					})
			})
			.catch((err) => {
				dispatch({
					type: REGISTER_ERROR,
					payload: err
				})
			});
};
