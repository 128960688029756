import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import middlewareAuth from '../../../middleware/middleware-auth';
import TAB_PATH from '../../../helpers/tabPath';
import { arrowBackOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../helpers/axiosInstance';
import handleError from '../../../helpers/handleError';
import { useHistory } from 'react-router';

const ProfileEdit = ({title}) => {

	middlewareAuth();

	const history = useHistory();
	const [form, setForm] = useState({
		first_name: '',
		last_name: '',
		email: '',
		phone: ''
	});
	const [showToast, setShowToast] = useState(false);
	const [toast, setToast] = useState({
		color: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
		message: process.env.REACT_APP_DEFAULT_TOAST_MESSAGE,
		duration: process.env.REACT_APP_DEFAULT_TOAST_DURATION
	});

	useEffect(() => {
		axiosInstance()
			.get('/my-profile')
			.then((res) => {
				const data = res.data.data;

				setForm({...form,
					['first_name']: data.first_name,
					['last_name']: data.last_name,
					['email']: data.email,
					['phone']: data.phone
				});
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}, [])

	const onChange = (e) => {
		setForm({...form, [e.target.name]: e.target.value});
	};

	const onSubmit = () => {
		axiosInstance()
			.post('/my-profile', {
				first_name: form.first_name,
				last_name: form.last_name,
				email: form.email,
				phone: form.phone,
			}).then((res) => {
				setToast({...toast,
					['color']: 'success',
					['message']: 'Operazione avvenuta con successo.',
				})
				setShowToast(true);

				setTimeout(() => {
					history.push(TAB_PATH + "/profile");
				}, 4000);
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	};

  return (
		<IonPage>
			<IonHeader className="ion-no-border" translucent="true">
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton color="secondary" className="toolbar-back-icon" text="" defaultHref={TAB_PATH + "/profile"} icon={arrowBackOutline} />
					</IonButtons>
					<IonTitle color="secondary" className="toolbar-title-with-icon">{title}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen="true">
				<IonToast
					color={toast.color}
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={toast.message}
					duration={toast.duration}
				/>
				<IonCard>
					<IonCardHeader>
						<IonCardSubtitle color="medium">Qui puoi modificare i dati che hai inserito in fase di registrazione.</IonCardSubtitle>
					</IonCardHeader>
					<IonCardContent>
						<IonList>
							<IonItem className="ion-no-padding">
								<IonLabel position="floating" color="medium">Nome</IonLabel>
								<IonInput
									value={form.first_name || ''}
									onIonChange={onChange}
									type="text"
									name="first_name"
								></IonInput>
							</IonItem>
							<IonItem className="ion-no-padding">
								<IonLabel position="floating" color="medium">Cognome</IonLabel>
								<IonInput
									value={form.last_name || ''}
									onIonChange={onChange}
									type="text"
									name="last_name"
								></IonInput>
							</IonItem>
							<IonItem className="ion-no-padding">
								<IonLabel position="floating" color="medium">Email</IonLabel>
								<IonInput
									value={form.email || ''}
									onIonChange={onChange}
									type="email"
									name="email"
								></IonInput>
							</IonItem>
							<IonItem className="ion-no-padding">
								<IonLabel position="floating" color="medium">Telefono</IonLabel>
								<IonInput
									value={form.phone || ''}
									onIonChange={onChange}
									type="text"
									name="phone"
								></IonInput>
							</IonItem>
							<br /><br />
							<IonButton expand="full" className="ion-no-margin" onClick={onSubmit}>
								Conferma
							</IonButton>
						</IonList>
					</IonCardContent>
				</IonCard>
    		</IonContent>
		</IonPage>
	);
};

export default ProfileEdit;
