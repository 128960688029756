const checkAllFieldAreFilled = (object, keys) => {

	let empty = keys.map((key) => {
		return object[key] === '' || object[key] === false  ? true : false;
	});

	return !empty.every(x => x === false);
};

export default checkAllFieldAreFilled;
