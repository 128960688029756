import { Route } from 'react-router-dom';
import {IonApp, isPlatform, setupConfig} from '@ionic/react';
/* Routes */
import { IonReactRouter } from '@ionic/react-router';
import routes from './routes';
/* Style */
import './assets/style/main.tsx';
/* Provider */
import {GlobalProvider} from './context/Provider';
import AppTabs from './AppTabs';
import RenderRoute from './components/routes/RenderRoute';
import TAB_PATH from './helpers/tabPath';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications';
import { useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';

const App: React.FC = () => {

	setupConfig({
		animated: !isPlatform('mobileweb'),
		statusTap: true,
		swipeBackEnabled: true,
	});

	const nullEntry: any[] = []
    const [notifications, setnotifications] = useState(nullEntry);
	const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

	useEffect(()=>{
		if ( (isPlatform('ios') || isPlatform('android')) && isPushNotificationsAvailable ) {
			PushNotifications.addListener('pushNotificationActionPerformed',
				(notification: ActionPerformed) => {
					let extraPayLoad = notification.notification.data.extraPayLoad;
					if ( extraPayLoad.goto.length > 0 ) {
						window.location.href = TAB_PATH + "/" + extraPayLoad.goto;
					}
				}
			);

			PushNotifications.checkPermissions().then((res) => {
				if (res.receive !== 'granted') {
					PushNotifications.requestPermissions().then((res) => {
						if (res.receive === 'denied') {
							console.log('Push Notification permission denied');
						} else {
							console.log('Push Notification permission granted');
							register();
						}
					});
				} else {
					register();
				}
			});
		}
    },[])

	const register = () => {
        if ( (isPlatform('ios') || isPlatform('android')) && isPushNotificationsAvailable ) {
			// Register with Apple / Google to receive push via APNS/FCM
			PushNotifications.register();

			// On success, we should be able to receive notifications
			PushNotifications.addListener('registration',
			(token: Token) => {
					console.log('Push Notification token: ' + token.value);
					localStorage.tokenPushPlugin = token.value;
				}
			);

			// Some issue with our setup and push will not work
			PushNotifications.addListener('registrationError',
				(error: any) => {
					alert('Error on registration: ' + JSON.stringify(error));
				}
			);

			// Show us the notification payload if the app is open on our device
			PushNotifications.addListener('pushNotificationReceived',
				(notification: PushNotificationSchema) => {
					setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
				}
			);

			// Method called when tapping on a notification
			PushNotifications.addListener('pushNotificationActionPerformed',
				(notification: ActionPerformed) => {
					setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
				}
			);
		}
    }

	return (
		<IonApp>
			<GlobalProvider>
				<IonReactRouter>
					{ routes.public.auth.map((route, index) => (
						<RenderRoute {...route} key={index} />
					))}
					<Route path={TAB_PATH}>
						<AppTabs />
					</Route>
				</IonReactRouter>
			</GlobalProvider>
		</IonApp>
	)
};

export default App;
