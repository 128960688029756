import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonContent, IonHeader, IonInfiniteScroll, IonInput, IonItem, IonLabel, IonModal, IonPage, IonSelect, IonSelectOption, IonText, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import middlewareAuth from '../../../middleware/middleware-auth';
import TAB_PATH from '../../../helpers/tabPath';
import { useHistory, useParams } from 'react-router';
import axiosInstance from '../../../helpers/axiosInstance';
import handleError from '../../../helpers/handleError';
import { useEffect, useState } from 'react';
import { arrowBackOutline } from 'ionicons/icons';
import { Link } from 'react-router-dom';
import renderHTML from '../../../helpers/renderHTML';

const Checkout = ({title}) => {

	middlewareAuth();

	const { companySlug } = useParams();
	const history = useHistory();
	const [cart, setCart] = useState([]);
	const [delAddressVisibility, setDelAddressVisibility] = useState(false);
	const [checkedDel, setCheckedDel] = useState(false);
	const [checkedGift, setCheckedGift] = useState(false);
	const [checkedTerms, setCheckedTerms] = useState(false);
	const [checked18, setChecked18] = useState(false);
	const [disclaimer18Enable, setDisclaimer18Enable] = useState(false);
	const [invFormVisibility, setInvFormVisibility] = useState(false);
	const [delFormVisibility, setDelFormVisibility] = useState(false);
	const [invAddId, setInvAddId] = useState('');
	const [delAddId, setDelAddId] = useState('');
	const [paymentMethod, setPaymentMethod] = useState('');
	const [feeAmount, setFeeAmount] = useState(0);
	const [totAmount, setTotAmount] = useState(0);
	const [feeVisibility, setFeeVisibility] = useState(false);
	const [hide, setHide] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [terms, setTerms] = useState('');
	const [invForm, setInvForm] = useState({
		billing_type: '',
		name: '',
		company: '',
		tax_code: '',
		vat_number: '',
		pec: '',
		ei_code: '',
		phone_number: '',
		address: '',
		city: '',
		zip_code: '',
		province: '',
		country: '',
		notes: ''
	});
	const [delForm, setDelForm] = useState({
		name: '',
		company: '',
		phone_number: '',
		address: '',
		city: '',
		zip_code: '',
		province: '',
		country: '',
		notes: ''
	});
	const [showToast, setShowToast] = useState(false);
	const [toast, setToast] = useState({
		color: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
		message: process.env.REACT_APP_DEFAULT_TOAST_MESSAGE,
		duration: process.env.REACT_APP_DEFAULT_TOAST_DURATION
	});

	let onInvChange = (e) => {
		setInvAddId('')
		setInvForm({...invForm, [e.target.name]: e.target.value});
	}

	let onDelChange = (e) => {
		setDelAddId('')
		setDelForm({...delForm, [e.target.name]: e.target.value});
	}

	let getCart = () => {
		axiosInstance()
			.get('/cart/' + companySlug)
			.then((res) => {
				setCart(res.data.data)
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	let submitCheckout = () => {
		axiosInstance()
			.post('/checkout/' + companySlug,
				{
					invoice_address_id: invAddId,
					invoice_form: invForm,
					invoice_different_from_delivery: delAddressVisibility,
					delivery_address_id: delAddId,
					delivery_form: delForm,
					is_gift: checkedGift,
					payment_method: paymentMethod,
					cart_id: cart.id,
					terms_and_conditions: checkedTerms,
					require_disclaimer_18: disclaimer18Enable,
					disclaimer_18: checked18,
				}
			).then((res) => {
				setToast({...toast,
					['color']: 'success',
					['message']: 'Grazie, il tuo ordine è stato inviato!',
				})
				setShowToast(true);
				localStorage.removeItem('cart_amount_' + companySlug);
				localStorage.removeItem('cart_items_count_' + companySlug);

				if ( res.data.type === 'int' ) {
					setTimeout(() => {
						history.push(TAB_PATH + "/" + res.data.redirect);
					}, 4000);
				} else {
					window.location.href = res.data.redirect;
					// history.push(TAB_PATH + "/companies");
				}
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}

	useEffect(() => {
		getCart();
	}, []);

	useEffect(() => {
		if ( !Array.isArray(cart) ) {
			let addresses = cart.customer.addresses.filter(el => {
				return el.type.includes('Fatturazione')
			});
			if ( addresses.length > 0 ) {
				setInvAddId(addresses[0].id)
			}

			let disclaimer18 = cart.details.filter(el => {
				return el.item.disclaimer_18 === 'Si';
			});
			if ( disclaimer18.length > 0 ) {
				setDisclaimer18Enable(true);
			} else {
				setDisclaimer18Enable(false);
			}
		}
	}, [cart]);

	useEffect(() => {
		invForm.billing_type === 'Privato' ? setHide(true) : setHide(false);
	}, [invForm.billing_type]);

	const onChangePaymentMethodSelect = (e) => {
		if (cart) {
			setPaymentMethod(e.detail.value.data);

			let fee = parseFloat(cart.amount.replace('.', '').replace(',', '.')) * parseFloat(e.detail.value.fee) / 100;
			setFeeAmount(fee.toFixed(2));
			setTotAmount(parseFloat(cart.amount.replace('.', '').replace(',', '.')) + parseFloat(fee.toFixed(2)));
			if ( parseFloat(e.detail.value.fee) > 0 ) {
				setFeeVisibility(true);
			} else {
				setFeeVisibility(false);
			}
		}
	};

	return (
		<IonPage>
			<IonHeader className="ion-no-border" translucent="true">
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton color="secondary" className="toolbar-back-icon" text="" defaultHref={TAB_PATH + "/companies/" + companySlug + "/cart"} icon={arrowBackOutline} />
					</IonButtons>
					<IonTitle color="secondary" className="toolbar-title-with-icon">{title}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen="true">
				<IonToast
					color={toast.color}
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={toast.message}
					duration={toast.duration}
				/>
				{/* Indirizzi di fatturazione */}
					<IonCard>
						<IonCardHeader>
							<IonCardSubtitle color="primary">Scegli un indirizzo di fatturazione e spedizione</IonCardSubtitle>
						</IonCardHeader>
						{ cart && cart.customer && cart.customer.addresses.filter(el => {
							return el.type.includes('Fatturazione')
						}).map((address, index) => (
							<IonCard
								key={index}
								onClick={e => setInvAddId(address.id)}
								className={ invAddId && invAddId == address.id ? "ion-card-border-selected" : null}
							>
								<IonCardHeader>
									<IonCardTitle color="primary">{address.name}</IonCardTitle>
									<IonCardSubtitle color="medium">{address.address} {address.city} ({address.province})</IonCardSubtitle>
								</IonCardHeader>
							</IonCard>
						))}
						{ !invFormVisibility ? (
							<IonCardContent>
								<IonButton size="small" className="btn-secondary" onClick={() => {
									setInvFormVisibility(true)
									setInvAddId('')
								}}>
									Nuovo indirizzo di fatturazione
								</IonButton>
							</IonCardContent>
						) : (
							<IonCardContent>
								<IonItem className="ion-no-padding">
									<IonLabel>Tipo di fatturazione</IonLabel>
									<IonSelect name="billing_type" interface="popover" onIonChange={onInvChange}>
										<IonSelectOption value="Privato">Privato</IonSelectOption>
										<IonSelectOption value="Azienda">Azienda</IonSelectOption>
									</IonSelect>
								</IonItem>
								<IonItem className="ion-no-padding">
									<IonLabel position="floating" color="medium">Nome e cognome</IonLabel>
									<IonInput
										type="text"
										name="name"
										value={invForm.name || ''}
										onIonChange={onInvChange}
									></IonInput>
								</IonItem>
								{ !hide ?
								(
									<IonItem className="ion-no-padding">
										<IonLabel position="floating" color="medium">Azienda</IonLabel>
										<IonInput
											type="text"
											name="company"
											value={invForm.company || ''}
											onIonChange={onInvChange}
										></IonInput>
									</IonItem>
								) : null }
								<IonItem className="ion-no-padding">
									<IonLabel position="floating" color="medium">Codice fiscale</IonLabel>
									<IonInput
										type="text"
										name="tax_code"
										value={invForm.tax_code || ''}
										onIonChange={onInvChange}
									></IonInput>
								</IonItem>
								{ !hide ?
								(
									<IonItem className="ion-no-padding">
										<IonLabel position="floating" color="medium">Partita IVA</IonLabel>
										<IonInput
											type="text"
											name="vat_number"
											value={invForm.vat_number || ''}
											onIonChange={onInvChange}
										></IonInput>
									</IonItem>
								) : null }
								{ !hide ?
								(
									<IonItem className="ion-no-padding">
										<IonLabel position="floating" color="medium">PEC</IonLabel>
										<IonInput
											type="email"
											name="pec"
											value={invForm.pec || ''}
											onIonChange={onInvChange}
										></IonInput>
									</IonItem>
								) : null }
								{ !hide ?
								(
									<IonItem className="ion-no-padding">
										<IonLabel position="floating" color="medium">Codice destinatario</IonLabel>
										<IonInput
											type="text"
											name="ei_code"
											value={invForm.ei_code || ''}
											onIonChange={onInvChange}
										></IonInput>
									</IonItem>
								) : null }
								<IonItem className="ion-no-padding">
									<IonLabel position="floating" color="medium">Telefono</IonLabel>
									<IonInput
										type="text"
										name="phone_number"
										value={invForm.phone_number || ''}
										onIonChange={onInvChange}
									></IonInput>
								</IonItem>
								<IonItem className="ion-no-padding">
									<IonLabel position="floating" color="medium">Indirizzo</IonLabel>
									<IonInput
										type="text"
										name="address"
										value={invForm.address || ''}
										onIonChange={onInvChange}
									></IonInput>
								</IonItem>
								<IonItem className="ion-no-padding">
									<IonLabel position="floating" color="medium">Città</IonLabel>
									<IonInput
										type="text"
										name="city"
										value={invForm.city || ''}
										onIonChange={onInvChange}
									></IonInput>
								</IonItem>
								<IonItem className="ion-no-padding">
									<IonLabel position="floating" color="medium">CAP</IonLabel>
									<IonInput
										type="text"
										name="zip_code"
										value={invForm.zip_code || ''}
										onIonChange={onInvChange}
									></IonInput>
								</IonItem>
								<IonItem className="ion-no-padding">
									<IonLabel position="floating" color="medium">Provincia</IonLabel>
									<IonInput
										type="text"
										name="province"
										value={invForm.province || ''}
										onIonChange={onInvChange}
									></IonInput>
								</IonItem>
								<IonItem className="ion-no-padding">
									<IonLabel position="floating" color="medium">Nazione</IonLabel>
									<IonInput
										type="text"
										name="country"
										value={invForm.country || ''}
										onIonChange={onInvChange}
									></IonInput>
								</IonItem>
								<IonItem className="ion-no-padding">
									<IonLabel position="floating" color="medium">Note</IonLabel>
									<IonInput
										type="text"
										name="notes"
										value={invForm.notes || ''}
										onIonChange={onInvChange}
									></IonInput>
								</IonItem>
							</IonCardContent>
						)}
					</IonCard>
				{/* Indirizzi di spedizione */}
				{ cart && cart.delivery_method === 'shipping' ? (
					<IonCard>
						<IonCardContent>
							<IonItem className="checkbox">
								<IonLabel>I dati di spedizione sono differenti?</IonLabel>
								<IonCheckbox checked={checkedDel} onIonChange={e => {
									setCheckedDel(!checkedDel)
									setDelAddressVisibility(!delAddressVisibility)
								}} />
							</IonItem>
						</IonCardContent>
						{ delAddressVisibility ? (
							<>
								<IonCardHeader>
									<IonCardSubtitle color="primary">Scegli un indirizzo di spedizione</IonCardSubtitle>
								</IonCardHeader>
								{ cart && cart.customer && cart.customer.addresses.filter(el => {
									return el.type.includes('Spedizione')
								}).map((address, index) => (
									<IonCard
										key={index}
										onClick={e => setDelAddId(address.id)}
										className={ delAddId && delAddId == address.id ? "ion-card-border-selected" : null}
									>
										<IonCardHeader>
											<IonCardTitle color="primary">{address.name}</IonCardTitle>
											<IonCardSubtitle color="medium">{address.address} {address.city} ({address.province})</IonCardSubtitle>
										</IonCardHeader>
									</IonCard>
								))}
								{ !delFormVisibility ? (
									<IonCardContent>
										<IonButton size="small" className="btn-secondary" onClick={() => {
											setDelFormVisibility(true)
											setDelAddId('')
										}}>
											Nuovo indirizzo di spedizione
										</IonButton>
									</IonCardContent>
								) : (
									<IonCardContent>
										<IonItem className="ion-no-padding">
											<IonLabel position="floating" color="medium" >Nome e cognome</IonLabel>
											<IonInput
												type="text"
												name="name"
												value={delForm.name || '' }
												onIonChange={onDelChange}
											></IonInput>
										</IonItem>
										<IonItem className="ion-no-padding">
											<IonLabel position="floating" color="medium">Azienda</IonLabel>
											<IonInput
												type="text"
												name="company"
												value={delForm.company || '' }
												onIonChange={onDelChange}
											></IonInput>
										</IonItem>
										<IonItem className="ion-no-padding">
											<IonLabel position="floating" color="medium">Telefono</IonLabel>
											<IonInput
												type="text"
												name="phone_number"
												value={delForm.phone_number || '' }
												onIonChange={onDelChange}
											></IonInput>
										</IonItem>
										<IonItem className="ion-no-padding">
											<IonLabel position="floating" color="medium">Indirizzo</IonLabel>
											<IonInput
												type="text"
												name="address"
												value={delForm.address || '' }
												onIonChange={onDelChange}
											></IonInput>
										</IonItem>
										<IonItem className="ion-no-padding">
											<IonLabel position="floating" color="medium">Città</IonLabel>
											<IonInput
												type="text"
												name="city"
												value={delForm.city || '' }
												onIonChange={onDelChange}
											></IonInput>
										</IonItem>
										<IonItem className="ion-no-padding">
											<IonLabel position="floating" color="medium">CAP</IonLabel>
											<IonInput
												type="text"
												name="zip_code"
												value={delForm.zip_code || '' }
												onIonChange={onDelChange}
											></IonInput>
										</IonItem>
										<IonItem className="ion-no-padding">
											<IonLabel position="floating" color="medium">Provincia</IonLabel>
											<IonInput
												type="text"
												name="province"
												value={delForm.province || '' }
												onIonChange={onDelChange}
											></IonInput>
										</IonItem>
										<IonItem className="ion-no-padding">
											<IonLabel position="floating" color="medium">Nazione</IonLabel>
											<IonInput
												type="text"
												name="country"
												value={delForm.country || '' }
												onIonChange={onDelChange}
											></IonInput>
										</IonItem>
										<IonItem className="ion-no-padding">
											<IonLabel position="floating" color="medium">Note</IonLabel>
											<IonInput
												type="text"
												name="notes"
												value={delForm.notes || '' }
												onIonChange={onDelChange}
											></IonInput>
										</IonItem>
									</IonCardContent>
								)}
							</>
						) : null }
					</IonCard>
				) : null }
					<IonCard>
						<IonCardContent>
							{/* Gift */}
							<IonItem className="checkbox">
								<IonLabel>È un regalo?</IonLabel>
								<IonCheckbox checked={checkedGift} onIonChange={e => {
									setCheckedGift(!checkedGift)
								}} />
							</IonItem>
							{/* Metodi pagamento */}
							{ cart ? (
								<>
									<IonCardSubtitle color="primary">Modalità di pagamento</IonCardSubtitle>
									<IonSelect
										interfaceOptions={{
											header: 'Scegli come desideri pagare',
											subHeader: '',
											cssClass: 'custom-action-sheet'
										}}
										interface="action-sheet"
										cancelText="Annulla"
										placeholder="Scegli una modalità di pagamento"
										onIonChange={onChangePaymentMethodSelect}
									>
										{cart && cart.payment_mode && cart.payment_mode.paypal_enabled ? (
											<IonSelectOption value={cart.payment_mode.paypal_enabled}>
												{ cart.payment_mode.paypal_enabled.label }
											</IonSelectOption>
										) : null }
										{cart && cart.payment_mode && cart.payment_mode.bank_transfer_enabled ? (
											<IonSelectOption value={cart.payment_mode.bank_transfer_enabled}>
												{ cart.payment_mode.bank_transfer_enabled.label }
											</IonSelectOption>
										) : null }
										{cart && cart.payment_mode && cart.payment_mode.cash_enabled ? (
											<IonSelectOption value={cart.payment_mode.cash_enabled}>
												{ cart.payment_mode.cash_enabled.label }
											</IonSelectOption>
										) : null }
									</IonSelect>
									<br />
								</>
							) : null }
						</IonCardContent>
					</IonCard>
					<IonCard>
						<IonCardContent>
							{/* Riepilogo */}
							{ cart && cart.details ? (
								<>
									<IonCardSubtitle color="primary">Riepilogo</IonCardSubtitle>
									<div className="list-checkout-items">
										{cart && cart.details && cart.details.map((detail, index) => (
											<div className="list-cart-item" key={index}>
												<IonText color="primary" className="checkout-price">
													{ detail.price ? detail.currency_code + ' ' + detail.price : null }
												</IonText>
												<IonText color="primary" className="checkout-quantity">
													{ parseInt(detail.quantity) }
												</IonText>
												<IonText color="primary" className="checkout-item">
													{ detail.name ? detail.name : null }
													{ detail.booking_datetime ? (
														<>
															<br />
															<IonText color="primary" className="checkout-item-booking">
																Prenotato il {detail.booking_datetime}
															</IonText>
														</>
													) : null }
												</IonText>
												<br />
											</div>
										))}
									</div>
									<IonItem className="checkout-total">
										<IonLabel color="medium">Costo di consegna</IonLabel>
										<IonInput type="text" value={cart.currency_code + ' ' + cart.shipping_total} readonly></IonInput>
									</IonItem>
									{ feeVisibility ? (
										<IonItem className="checkout-total">
											<IonLabel color="medium">Commissione pagamento</IonLabel>
											<IonInput type="text" value={cart.currency_code + ' ' + parseFloat(feeAmount).toFixed(2).replace('.', ',')} readonly></IonInput>
										</IonItem>
									) : null }
									<IonItem className="checkout-total">
										<IonLabel color="medium">Totale</IonLabel>
										<IonInput type="text" value={totAmount > 0 ? cart.currency_code + ' ' + parseFloat(totAmount).toFixed(2).replace('.', ',') : cart.currency_code + ' ' + cart.amount} readonly></IonInput>
									</IonItem>
								</>
							) : null }
							{/* Termini e condizioni */}
							<IonItem className="checkbox terms-checkbox">
								<IonLabel>Accetto i termini e condizioni</IonLabel>
								<IonCheckbox checked={checkedTerms} onIonChange={e => {
									setCheckedTerms(!checkedTerms)
								}} />
							</IonItem>
							<IonModal isOpen={showModal}>
								<IonContent>
									<IonItem>
										{cart && cart.order_terms ? renderHTML(cart.order_terms) : null}
									</IonItem>
								</IonContent>
									<IonButton expand="full" className="ion-no-margin" onClick={() => setShowModal(false)}>Chiudi</IonButton>
							</IonModal>
							<IonText className="terms-link" onClick={() => setShowModal(true)}>
								Leggi i termini e le condizioni
							</IonText>
							<br />
							{/* Disclaimer 18+ */}
							{ disclaimer18Enable === true ? (
								<IonItem className="checkbox">
									<IonLabel>Confermo di avere almeno 18 anni</IonLabel>
									<IonCheckbox checked={checked18} onIonChange={e => {
										setChecked18(!checked18)
									}} />
								</IonItem>
							 ) : null }
							<br />
							{/* Paga */}
							<IonButton expand="full" className="ion-no-margin" onClick={submitCheckout}>
								Invia l'ordine!
							</IonButton>
						</IonCardContent>
					</IonCard>
    		</IonContent>
		</IonPage>
	);
};

export default Checkout;
