import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonContent, IonHeader, IonItem, IonPage, IonTitle, IonToast, IonToolbar } from '@ionic/react';
import middlewareAuth from '../../../middleware/middleware-auth';
import TAB_PATH from '../../../helpers/tabPath';
import { arrowBackOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../helpers/axiosInstance';
import handleError from '../../../helpers/handleError';

const Addresses = ({title}) => {

	middlewareAuth();

	const [addresses, setAddresses] = useState([]);
	const [showToast, setShowToast] = useState(false);
	const [toast, setToast] = useState({
		color: process.env.REACT_APP_DEFAULT_TOAST_COLOR,
		message: process.env.REACT_APP_DEFAULT_TOAST_MESSAGE,
		duration: process.env.REACT_APP_DEFAULT_TOAST_DURATION
	});

	useEffect(() => {
		axiosInstance()
			.get('/addresses')
			.then((res) => {
				setAddresses(res.data.data)
			})
			.catch((err) => {
				handleError(err, toast, setToast, setShowToast)
			});
	}, [])

	return (
		<IonPage>
			<IonHeader className="ion-no-border" translucent="true">
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton color="secondary" className="toolbar-back-icon" text="" defaultHref={TAB_PATH + "/profile"} icon={arrowBackOutline} />
					</IonButtons>
					<IonTitle color="secondary" className="toolbar-title-with-icon">{title}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent fullscreen="true">
				<IonToast
					color={toast.color}
					isOpen={showToast}
					onDidDismiss={() => setShowToast(false)}
					message={toast.message}
					duration={toast.duration}
				/>
				{ addresses && addresses.map((address, index) => (
					<IonCard href={TAB_PATH + "/profile/addresses/" + address.id} key={index}>
						<IonCardHeader>
							<IonCardTitle color="primary">{address.name}</IonCardTitle>
							<IonCardSubtitle color="medium">{address.address} {address.city} ({address.province})</IonCardSubtitle>
							<IonCardSubtitle color="medium">Indirizzo di {address.type}</IonCardSubtitle>
						</IonCardHeader>
					</IonCard>
				))}
    		</IonContent>
		</IonPage>
	);
};

export default Addresses;
