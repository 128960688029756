import { useHistory } from "react-router";
import isAuthenticated from "../helpers/isAuthenticated";
import TAB_PATH from "../helpers/tabPath";

export default () => {
    const history = useHistory();

	if ( isAuthenticated() ) {
		history.push(TAB_PATH + "/companies");
	}
}
